import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {AccountSelectorComponent} from './account-selector.component';
import {MatMenuModule} from '@angular/material/menu';
import {NbIconModule, NbInputModule} from '@nebular/theme';
import {MatListModule} from '@angular/material/list';
import { DialogAccountComponent } from './dialog/dialog-account/dialog-account.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [AccountSelectorComponent, DialogAccountComponent],
  imports: [CommonModule,
    TranslateModule,
    MatMenuModule,
    NbIconModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    NbInputModule, MatButtonModule],
  exports: [AccountSelectorComponent],
})
export class AccountSelectorModule {}
