import { Injectable } from '@angular/core';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {
  CanActivate,
  Router,
} from '@angular/router';

@Injectable()
export class AuthMasterGuard implements CanActivate {
  usuario: {
    userId: string;
    roles: string;
  };

  constructor(
    private authService: NbAuthService,
    private router: Router) {
    this.getUser();
  }

  canActivate() {
    if (!this.usuario.roles.includes('ROLE_ADMIN')) {
      this.router.navigate(['/']).then(r => r);
      return false;
    }
    return true;
  }

  getUser() {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.usuario = token.getPayload();
      }
    });
  }
}
