import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'ngx-cnpj-input',
  template: `
    <label class="label">
      {{ label | translate }}
      {{ required ? "*" : "" }}</label>
    <input
      type="text"
      class="form-control"
      [ngModel]="value"
      (input)="onInputChange($event.target.value)"
      placeholder="01.452.25/0001-19"
      pattern="[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}"
      [disabled]="disabled"
    />
  `,
})
export class CnpjInputComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
