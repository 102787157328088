import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {META_INFO} from '../data/meta.config';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class MetaService {
  nameSite = 'Kirk Digital';

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) {}

  updateMeta(route: string) {
    if ( Object.prototype.hasOwnProperty.call(META_INFO, route)) {
      const {title, description} = META_INFO[route];
      this.translate.get(title).subscribe((res: string) => {
        this.updateTitle(`${res} - ${this.nameSite}`);
        if (description) {
          this.translate.instant(description);
        }
      });
    }
  }

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }
}
