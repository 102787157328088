import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserTenantList } from '../model/usertenantlist.model';
import {DadosContaService} from '../../../shared/services/dadosconta.service';
import {Router} from '@angular/router';
import {PermissionUserService} from '../../../pages/registration/roles/service/permissionuser.service';
import {NotificationService} from '../../../shared/services/notificationService.service';
import {CarregamentoService} from '../../../shared/layout/loading/carregamento.service';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {ConfigSystemService} from '../../../pages/configuration/service/configSystem.service';
import {EncryptService} from '../../../shared/services/encrypt.service';
import {PrecadastroService} from '../../../pages/precadastro/service/precadastro.service';
import {OnbordingService} from '../../../pages/home/onbording/service/onbording.service';
import {PersonService} from '../../../pages/person/service/person.service';

const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/usertenant`;
const USER_ACCOUNT_KEY = 'userTenantListCache';

@Injectable({
  providedIn: 'root',
})
export class UserTenantListService implements OnDestroy {
  private userTenantListCache$?: Observable<UserTenantList[]>;
  private userTenantListCacheSubject = new BehaviorSubject<UserTenantList[]>([]);

  private subscription: Subscription;
  _sessionStorage = window.sessionStorage;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dadosContaService: DadosContaService,
    private permissionuser: PermissionUserService,
    private notificar: NotificationService,
    private carregamento: CarregamentoService,
    private configSystemService: ConfigSystemService,
    private encryptService: EncryptService,
    private personService: PersonService,
    private precadastroService: PrecadastroService,
    private onboardingService: OnbordingService,
  ) {}

  getAll(): Observable<UserTenantList[]> {
    if (!this.userTenantListCache$) {
      // Tenta obter do sessionStorage
      const cachedData = this.getDados();
      if (cachedData) {
        this.userTenantListCache$ = of(cachedData); // Retorna um observable de dados existentes
      } else {
        // Se não houver dados em cache, realiza a consulta à API
        this.userTenantListCache$ = this.http.get<UserTenantList[]>(baseUrl).pipe(
          shareReplay(1),
          catchError((error) => {
            console.error('Erro ao obter dados do usuário e do tenant:', error);
            return of([]); // Retorna um observable vazio em caso de erro
          }),
          tap((data) => {
            this.saveDados(data);
          }),
        );
      }
    }
    return this.userTenantListCache$;
  }

  getStoredData(): Observable<UserTenantList[]> {
    // Simplesmente retorna o resultado do método getAll, que já possui o shareReplay
    return this.getAll();
  }

  saveDados(dados: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(dados));
    this._sessionStorage.setItem(USER_ACCOUNT_KEY, dadosCriptografados);
  }

  getDados(): any {
    const key = this._sessionStorage.getItem(USER_ACCOUNT_KEY);
    if (key) {
      const dadosDescriptografados = this.encryptService.decryptData(key);
      return JSON.parse(dadosDescriptografados);
    }
    return '';
  }

  post(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/enter`, data);
  }

  clearCache() {
    this.userTenantListCache$ = null;
    this.userTenantListCacheSubject.next([]);
    this._sessionStorage.removeItem(USER_ACCOUNT_KEY);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setConta(event, nameAccount) {
    this.dadosContaService.clearDados();
    this.carregamento.show('loading.loading');
    this.post(event).toPromise()
      .then((data) => {
        if (data.statusCodeValue === 200) {
          this.dadosContaService.saveDadosUser(data.body.user_instance);
          this.dadosContaService.saveDadosAccount(nameAccount);
          this.personService.verifyPersonMe();
          // Após salvar os dados, executar as operações assíncronas restantes em paralelo
          return Promise.all([
            this.permissionuser.carregarPermissoes(),
            new Promise(resolve => setTimeout(resolve, 2000)),
            this.carregamento.show('loading.account'),
            this.configSystemService.init(),
            this.precadastroService.verify(),
            this.onboardingService.verify(),
            this.router.navigate(['main/home']).then(() => new Promise(resolve => setTimeout(resolve, 1000))),
          ]);
        } else {
          throw new Error(data.body.message);
        }
      })
      .then(() => {
        // Após a conclusão de todas as operações, ocultar o carregamento
        this.carregamento.show('loading.success');
        return new Promise(resolve => setTimeout(resolve, 3000));
      })
      .then(() => {
        this.carregamento.hide();
      })
      .catch((error) => {
        this.carregamento.hide();
        this.notificar.showError(error.message);
      });
  }

  /*  setConta_old(event, nameAccount) {
    this.dadosContaService.clearDados();
    this.carregamento.show('loading.loading');
    this.post(event).toPromise()
      .then((data) => {
        if (data.statusCodeValue === 200) {
          this.dadosContaService.saveDadosUser(data.body.user_instance);
          this.dadosContaService.saveDadosAccount(nameAccount);
          this.router.navigate(['main/home']).then(() => {
            return new Promise(resolve => setTimeout(resolve, 1000));
          })
            .then(() => {
              this.carregamento.show('loading.account');
              return this.configSystemService.init();

            })
            .then(() => {
              return new Promise(resolve => setTimeout(resolve, 3000));
            })
            .then(() => {
              this.carregamento.show('loading.user');
              return this.permissionuser.carregarPermissoes();
            })
            .then(() => {
              return this.personService.verifyPersonMe();
            })
            .then(() => {
              return this.precadastroService.verify();
            })
            .then(() => {
              return this.onboardingService.verify();
            })
            .then(() => {
              this.carregamento.show('loading.permission');
              return new Promise(resolve => setTimeout(resolve, 4000));
            })
            .then(() => {
              this.carregamento.show('loading.success');
              return new Promise(resolve => setTimeout(resolve, 4000));
            })
            .then(() => {
              this.carregamento.hide();
            })
            .catch((error) => {
              this.carregamento.hide();
              this.notificar.showError(error.message);
            });
        } else {
          this.carregamento.hide();
          this.notificar.showWarning(data.body.message);
        }
      })
      .catch((error) => {
        this.carregamento.hide();
        this.notificar.showError(error.error.message);
      });
  }*/

}
