<nb-layout center>
  <nb-layout-column>
    <br>
    <ngx-logo-menu></ngx-logo-menu>
    <ngx-card [loading]="loading">
      <nb-card-header>
        <nb-action>
          <nav class="navigation">
            <a href="#" aria-label="Back" class="link back-link">
              <nb-icon icon="arrow-back-outline" status="primary"></nb-icon>
            </a>
          </nav>
        </nb-action>
        <div class="text-center">
          <h1 class="title">{{ 'message.register' | translate }}</h1>
          <p class="sub-title">{{ 'auth.createAccountSubTitle' | translate: {name: nameAccount} }}</p>
        </div>
      </nb-card-header>
      <nb-card-body>

        <h3 class="text-center font-weight-bold large-text font-size-24 mb-3 mt-3">
          {{ 'auth.inputData' | translate }}
        </h3>

        <div class="justify-content-center d-flex mb-2 mt-2">
          <ngx-person-data-layout
            [person]="personData"
            (personChange)="personData = $event"
            (isFormValid)="isFormValid = $event"
            [enableRoles]="false"
            [enablePhoto]="false"
            [mostrarCreateAccess]="false"
            [enableBirthdate]="false"
            (input)="updateFormValidity()"
          >
          </ngx-person-data-layout>
        </div>
        <div class="justify-content-center d-flex mb-2 mt-2">
          <div class="col-12 col-md-6">
            <ngx-checkbox-input
              [label]="'auth.acceptTermsAndPolicy' | translate: { link1: linkUrl1, link2: linkUrl2 }"
              [(checked)]="acceptTerms"
              (checkedChange)="acceptTerms = $event"
              [required]="true"
              (change)="updateFormValidity()"
            ></ngx-checkbox-input>
          </div>
          <div class="col-12 col-md-6">
            <re-captcha (resolved)="resolved($event)" siteKey="6LcC-N4eAAAAAMQ-3U0KvZ-1dX6WaNTNHsOvTlAS"></re-captcha>
          </div>
        </div>


          <button nbButton (click)="saveUser()" fullWidth
                  [disabled]="!isFormValid || !acceptTerms || !resolvedCaptcha"
                  status="primary">{{ 'onboarding.finish' | translate }}</button>

      </nb-card-body>

    </ngx-card>

  </nb-layout-column>

</nb-layout>
