import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private toastrService: NbToastrService,
    private translate: TranslateService,
  ) {}

  showSuccess(messageKey: string): void {
    const translatedMessage = this.translate.instant(messageKey);
    this.toastrService.success(translatedMessage, this.translate.instant('messagebox.success'));
  }

  showError(messageKey: string): void {
    const translatedMessage = this.translate.instant(messageKey);
    this.toastrService.danger(translatedMessage, this.translate.instant('messagebox.error'));
  }

  showWarning(messageKey: string): void {
    const translatedMessage = this.translate.instant(messageKey);
    this.toastrService.warning(translatedMessage, this.translate.instant('messagebox.warning'));
  }

  showInfo(messageKey: string): void {
    const translatedMessage = this.translate.instant(messageKey);
    this.toastrService.info(translatedMessage, this.translate.instant('messagebox.info'));
  }

  showErroInesperado(): void {
    this.toastrService.danger(this.translate.instant('messagebox.unexpectedError'), this.translate.instant('messagebox.error'));
  }

  getTraduzir(messageKey: string, params: any = {}): string {
    return this.translate.instant(messageKey, params);
  }

  showAcessoNegado() {
    this.toastrService.danger(this.translate.instant('messagebox.accessDenied'), this.translate.instant('messagebox.error'));
  }
}
