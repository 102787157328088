<nb-layout center>
  <nb-layout-column>
    <br>
    <ngx-logo-menu></ngx-logo-menu>
    <ngx-card [loading]="loading">
      <nb-card-body>
        <nb-stepper #stepper [linear]="true">
          <nb-step label="{{ 'actions.start' | translate }}">
            <div class="step-container">
              <div class="row">
                <div class="col-md-6">
                  <div class="image-container">
                    <img
                      [src]="'assets/images/image-1.png'"
                      [alt]="'onboarding.title' | translate"
                      class="image"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <h5>{{ 'site.title' | translate }}</h5>
                  {{ 'site.subtitle' | translate }}
                  <br>
                  <br>
                  <button nbButton nbStepperNext fullWidth  status="primary">
                    {{ 'onboarding.start' | translate }}
                    <nb-icon icon="arrow-forward-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>
            <br>
            <span class="text-muted text-center">
              <nb-icon icon="credit-card-outline"></nb-icon>
              {{ 'site.title3' | translate }}</span>
          </nb-step>

          <nb-step [stepControl]="movieForm" label="Dados">
            <form #movieForm="ngForm" class="step-container">
              <h5>{{ 'auth.inputData' | translate }}</h5>
                <div class="row">
                  <div class="col-md-6">
                    <ngx-input-field
                      [label]="'generic.name'"
                      [value]="data.name"
                      (valueChange)="data.name = $event"
                      [disabled]="false"
                      [required]="true"
                      type="text"
                      (input)="updateFormValidity()"
                    ></ngx-input-field>
                  </div>

                  <div class="col-md-6">
                    <ngx-cnpj-input
                      [label]="'account.cnpj'"
                      [value]="data.doc"
                      [required]="true"
                      (valueChange)="data.doc = $event"
                    ></ngx-cnpj-input>
                  </div>
                  <div class="col-md-6">
                    <ngx-input-field
                      [label]="'generic.email'"
                      [placeholder]="'email@exemple.com'"
                      [value]="data.email"
                      [required]="true"
                      type="email"
                      (valueChange)="data.email = $event"
                    ></ngx-input-field>
                  </div>
                  <div class="form-group col-sm-6">
                    <ngx-phone-input
                      [label]="'generic.phone'"
                      [value]="data.mobile"
                      [required]="true"
                      (valueChange)="data.mobile = $event"
                    ></ngx-phone-input>
                  </div>
                </div>
              <button nbButton nbStepperPrevious>{{ 'onboarding.previous' | translate }}</button>
              <button nbButton [disabled]="!data.name || !data.doc || !data.email || !data.mobile" nbStepperNext>{{ 'onboarding.next' | translate }}</button>
            </form>
          </nb-step>

          <nb-step [stepControl]="somethingForm" label="Termos de Uso">
            <form #somethingForm="ngForm" class="step-container">
              <div class="row">
              <div class="col-12">
                <re-captcha (resolved)="resolved($event)"
                            siteKey="6LcC-N4eAAAAAMQ-3U0KvZ-1dX6WaNTNHsOvTlAS"></re-captcha>
              </div>
              <div class="col-12">
                <ngx-checkbox-input
                  [label]="'auth.acceptTermsAndPolicy' | translate: { link1: linkUrl1, link2: linkUrl2 }"
                  [(checked)]="acceptTerms"
                  (checkedChange)="acceptTerms = $event"
                  [required]="true"
                  (change)="updateFormValidity()"
                  [(ngModel)]="acceptTerms"
                ></ngx-checkbox-input>
              </div>
              </div>
              <button nbButton nbStepperPrevious>{{ 'onboarding.previous' | translate }}</button>
              <button nbButton nbStepperNext [disabled]="!acceptTerms || !resolvedCaptcha">{{ 'onboarding.next' | translate }}</button>
            </form>
          </nb-step>

          <nb-step [hidden]="true">
            <div class="step-container">
              <h5>{{ 'onboarding.finish2' | translate }}</h5>
              {{ 'onboarding.text1' | translate }}<br><br><br>
              {{ 'onboarding.text2' | translate }}<br>
              <br><br>

              <button nbButton (click)="saveUser()" fullWidth
                      [disabled]="!isFormValid || !acceptTerms || !resolvedCaptcha"
                      status="primary">{{ 'onboarding.conclude' | translate }}</button>
            </div>
          </nb-step>
        </nb-stepper>
      </nb-card-body>
    </ngx-card>
  </nb-layout-column>
</nb-layout>
