import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {PersonPrecadastro} from '../../person/model/person.model';
import {Page} from '../../../shared/interface/page';

const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/person/precadastro`;

@Injectable({
  providedIn: 'root',
})
export class PrecadastroService {
  _sessionStorage = window.sessionStorage;
  private _externalChange = new Subject<void>();

  constructor(private http: HttpClient) { }

  getAll(page: number, pageSize: number, filter: string, status: any): Observable<Page<PersonPrecadastro>> {
    const params = new HttpParams()
      .set('status', status.toString())
      .set('name', filter.toString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<Page<PersonPrecadastro>>(`${baseUrl}`, { params });
  }

  get(id: any): Observable<PersonPrecadastro> {
    return this.http.get<PersonPrecadastro>(`${baseUrl}/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }

  aprovar(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}/approved`, data);
  }

  reprovar(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  hasPending(): Observable<any> {
    return this.http.get(`${baseUrl}/has-pending`);
  }

  get externalChange(): Observable<void> {
    return this._externalChange.asObservable();
  }

  verify() {
    const data = this._sessionStorage.getItem('precadastroCache');
    if (data) {
      return JSON.parse(data);
    }
      this.hasPending().subscribe((res) => {
      this._sessionStorage.setItem('precadastroCache', JSON.stringify(res));
      this._externalChange.next();
      return res;
    });
  }
}
