import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxRound' })
export class RoundPipe implements PipeTransform {

  /*  funcao para arredondar números para o número inteiro mais próximo
  <span>{{ 3.7 | ngxRound }}</span> <!-- Exibirá "4" -->*/
  transform(input: number): number {
    return Math.round(input);
  }
}
