import {Component} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-zoom-photo-modal',
  template: `
  <nb-card>
  <nb-card-header>
    Visualizar imagem
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <img class="img-fluid" [src]="photo" style="max-width: 140%; max-height: 500px;" />
      </div>
    </div>
  </nb-card-body>
  <ngx-card-footer>
    <button nbButton status="basic" (click)="close()">Fechar</button>
  </ngx-card-footer>
</nb-card>

  `,
})
export class ZoomPhotoModalComponent {
  photo: string | null = null;

  constructor(protected ref: NbDialogRef<ZoomPhotoModalComponent>) {}

  close() {
    this.ref.close();
  }
}
