<nb-layout center>
  <nb-layout-column>
    <br>
    <ngx-logo-menu></ngx-logo-menu>
    <ngx-card>
      <nb-card-header>
        <nb-action>
          <nav class="navigation">
            <a href="#" aria-label="Back" class="link back-link">
              <nb-icon icon="arrow-back-outline" status="primary"></nb-icon>
            </a>
          </nav>
        </nb-action>
        <div class="text-center">
          <h6 class="title">{{ 'site.subtitleSite' | translate }}</h6>
        </div>
      </nb-card-header>
      <nb-card-body>
     {{ 'site.featuresSubtitle' | translate }}
      </nb-card-body>
      <nb-card-body>
        <br><h5 class="ud-widget-title text-center">{{ 'site.footer.downloadApp' | translate }}</h5>
        <div class="ud-widget">

          <ul class="ud-widget-brands row">
            <li *ngIf="linkGoogle">
              <button nbButton status="primary">
            <a href="{{linkGoogle}}" rel="nofollow noopner" target="_blank">
              <img src="https://kirkdigital.cloud/assets/images/play-store.png" alt="Play Store"></a></button></li>
            <li *ngIf="linkApple">
              <button nbButton status="primary">
            <a href="{{linkApple}}" rel="nofollow noopner" target="_blank">
              <img src="https://kirkdigital.cloud/assets/images/app-store.png" alt="App Store"></a></button></li></ul>
        </div>
      </nb-card-body>

    </ngx-card>

  </nb-layout-column>

</nb-layout>
