
import { LanguageService } from '../../services/language.service';
import { Component, Inject, OnInit } from '@angular/core';
import {NB_WINDOW, NbButtonModule, NbContextMenuModule, NbIconModule, NbMenuItem, NbMenuService} from '@nebular/theme';
import {filter, map} from 'rxjs/operators';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {NgForOf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'ngx-language-selector',
  standalone: true,
  template: `
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <nb-icon icon="globe-outline"></nb-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div *ngFor="let menu of items">
        <button mat-menu-item (click)="changeLanguage({ item: menu })">
          {{menu.title | translate}}</button>
      </div>
    </mat-menu>
  `,
  imports: [
    NbContextMenuModule,
    NbIconModule,
    NbButtonModule,
    MatButtonModule,
    MatMenuModule,
    NgForOf,
    TranslateModule,
  ],
})

export class LanguageSelectorComponent implements OnInit {
  selectedLanguage: string;
  items: NbMenuItem[] = [
    { title: 'English', data: { code: 'en' } },
    { title: 'Español', data: { code: 'es' } },
    { title: 'Português', data: { code: 'pt' } },
    { title: '中文 (Beta)', data: { code: 'zh' } },
    { title: 'Japanese (Beta)', data: { code: 'ja' } },
  ];

  constructor(
    private languageService: LanguageService,
    @Inject(NB_WINDOW) private window,
    private nbMenuService: NbMenuService,
  ) {
    this.selectedLanguage = this.languageService.getSelectedLanguage();
  }

  ngOnInit() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item }) => item), // Usar item diretamente
      )
      .subscribe(item => {
        this.changeLanguage({ item }); // Chamar a função changeLanguage com o item clicado
      });
  }

  changeLanguage(event: { item: NbMenuItem }) {
    const language = event.item.data;
    this.languageService.setSelectedLanguage(language.code);
    this.selectedLanguage = language.code;

    this.languageService.useLanguage(language.code);
    this.window.location.reload();
  }
}
