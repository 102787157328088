import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { DadosContaService } from './dadosconta.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dadosContaService: DadosContaService) {}

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['auth/login']);
          this.dadosContaService.clearDados();
        }
      }));
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isXtenantIDSet()) {
      return true; // Permite o acesso à rota se o xtenantID estiver definido.
    } else {
      this.router.navigate(['/core/account']);
      return false; // Impede o acesso à rota.
    }
  }
}
