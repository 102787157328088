import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit, OnDestroy, OnChanges,
} from '@angular/core';
import {Person} from '../../../pages/person/model/person.model';
import {getSexOptions} from '../../data/options';
import {ConfigSystemService} from '../../../pages/configuration/service/configSystem.service';
import {ConfigSystem} from '../../../pages/configuration/model/ConfigSystem';

@Component({
  selector: 'ngx-person-data-layout',
  template: `<div class="row">
    <div class="col-12" *ngIf="enablePhoto">
      <ngx-imagem-profile
        [namePerson]="person.name"
        [base64Image]="person.image"
        (base64ImageChange)="person.image = $event"
      ></ngx-imagem-profile>
    </div>
    <div class="col-md-6 col-sm-4">
      <ngx-input-field
        [label]="'generic.name'"
        [value]="person.name"
        (valueChange)="person.name = $event"
        [disabled]="false"
        [required]="isNameObrigatory"
        type="text"
        (input)="updateFormValidity()"
      ></ngx-input-field>
    </div>

    <div class="col-md-6 col-sm-4">
      <ngx-input-field
        [label]="'generic.email'"
        [value]="person.email"
        type="email"
        (valueChange)="person.email = $event"
        [required]="isEmailObrigatory"
        [disabled]="disabledEmail"
        (input)="updateFormValidity(); isEmailValid()"
      ></ngx-input-field>
    </div>

    <div class="col-md-4 col-sm-4">
      <ngx-phone-input
        [label]="'generic.phone'"
        [value]="person.phone"
        (valueChange)="person.phone = $event"
        [required]="isPhoneObrigatory"
        (input)="updateFormValidity()"
      ></ngx-phone-input>
    </div>

    <div class="col-md-3 col-sm-4" *ngIf="enableBirthdate">
      <ngx-data-input
        [label]="'generic.birthAt'"
        [value]="person.birthAt"
        (valueChange)="person.birthAt = $event"
        [required]="isBirthObrigatory"
        (change)="updateFormValidity()"
        [disabled]="false"
      ></ngx-data-input>
    </div>

    <div class="col-md-2 col-sm-4">
      <ngx-radio-input
        [label]="'gender.title'"
        [options]="sexoOptions"
        [value]="person.sex"
        (valueChange)="person.sex = $event"
        (input)="updateFormValidity()"
        [required]="isSexObrigatory"
      ></ngx-radio-input>
    </div>
    <div class="col-sm-3 col-md-3" *ngIf="enableRoles">
      <ngx-select-input
        [label]="'generic.role'"
        [value]="person.roles"
        (valueChange)="person.roles = $event"
        [options]="rolesOptions"
        [required]="true"
        [disabled]="false"
        (input)="updateFormValidity()"
      ></ngx-select-input>
    </div>
      <div class="col-md-12 mt-3" *ngIf="mostrarCreateAccess">
        <ngx-checkbox-input
          [label]="'person.createAccess'"
          [(checked)]="person.createAccess"
          (checkedChange)="person.createAccess = $event"
          [required]="false"
          (change)="verifyEmailObrigatory()"
          [title]="'person.createAccessMessage'"
        ></ngx-checkbox-input>
      </div>
  </div>`,
})

export class PersonDataFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() person: Person;
  @Input() rolesOptions: any[];
  @Input() disabledEmail = false;
  @Input() enablePhoto = true;
  @Input() enableRoles = true;
  @Input() enableBirthdate = true;
  @Output() personChange = new EventEmitter<Person>();
  @Output() isFormValid = new EventEmitter<boolean>();

  @Input() mostrarCreateAccess: boolean = false;

  isNameObrigatory = true;
  isEmailObrigatory = true;
  isPhoneObrigatory = true;
  isBirthObrigatory = true;
  isSexObrigatory = false;

  configCampos: ConfigSystem = {};
  sexoOptions = getSexOptions();

  constructor(private configSystem: ConfigSystemService) {}

  private verifyObrigatoryFields() {
    this.configCampos = this.configSystem.getConfigConta();
    if (this.configCampos) {
      this.isNameObrigatory = true;
      this.isEmailObrigatory = this.configCampos.obgEmail;
      this.isPhoneObrigatory = this.configCampos.obgMobile;
      this.isBirthObrigatory = this.configCampos.obgBirth;
      this.isSexObrigatory = this.configCampos.obgSex;
    }
  }

  ngOnInit() {
    this.verifyObrigatoryFields();
    this.updateFormValidity();
  }

  updateFormValidity() {
    const isFormValid =
      (!this.isNameObrigatory || (this.person.name && this.person.name.trim() !== '')) &&
      (!this.isEmailObrigatory || (this.person.email && this.person.email.trim() !== '')) &&
      (!this.isPhoneObrigatory || (this.person.phone && this.person.phone.trim() !== '')) &&
      (!this.isBirthObrigatory || (this.person.birthAt !== undefined && this.person.birthAt !== null)) &&
      (!this.isSexObrigatory || (this.person.sex !== undefined && this.person.sex !== null));
    // Emitir o resultado
    this.isFormValid.emit(isFormValid);
  }

  ngOnDestroy() {
    this.isFormValid.complete();
  }

  verifyEmailObrigatory() {
    if (this.person.createAccess === true) {
      this.isEmailObrigatory = true;
    } else {
      this.isEmailObrigatory = false;
    }
    this.updateFormValidity();
  }

  isEmailValid() {
    const email = this.person.email;
    if (email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (!emailRegex.test(email)) {
        this.isFormValid.emit(false); // Emitir um evento informando que o formulário é inválido
      } else {
        this.updateFormValidity();
      }
    }
  }

  ngOnChanges() {
    this.updateFormValidity();
  }
}
