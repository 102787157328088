export const META_INFO = {
  '/main/home': {
    title: 'navigation.home',
  },
  '/main/dashboard': {
    title: 'navigation.dashboard',
  },
  '/main/prayer': {
    title: 'navigation.prayers',
  },
  '/main/financial': {
    title: 'navigation.financial',
  },
  '/main/visitor': {
    title: 'navigation.visitors',
  },
  '/main/person': {
    title: 'navigation.persons',
  },
  '/main/group': {
    title: 'navigation.groups',
  },
  '/main/events': {
    title: 'navigation.events',
  },
  '/main/configuration': {
    title: 'navigation.settings',
  },
  '/main/my-offers': {
    title: 'navigation.myOffers',
  },
  '/main/my-group': {
    title: 'navigation.myGroups',
  },
  '/main/my-data': {
    title: 'navigation.myData',
  },
  '/main/registration/banks': {
    title: 'navigation.registrations',
  },
  '/main/registration/category': {
    title: 'navigation.registrations',
  },
  '/main/registration/roles': {
    title: 'navigation.registrations',
  },
  '/main/registration/departments': {
    title: 'navigation.registrations',
  },
  '/main/message': {
    title: 'navigation.messages',
  },
  '/main/calendar': {
    title: 'navigation.calendar',
  },
  '/main/cult': {
    title: 'navigation.cults',
  },
  '/main/reports': {
    title: 'navigation.report',
  },
  '/main/alter-password': {
    title: 'actions.alterPassword',
  },
  '/core/account': {
    title: 'navigation.accounts',
  },
  '/core/licenses': {
    title: 'navigation.licenses',
  },
  '/core/settings': {
    title: 'navigation.settings',
  },
  '/core/users': {
    title: 'navigation.users',
  },
  '/auth/login': {
    title: 'navigation.login',
    description: 'site.subtitleSite',
  },
  '/register': {
    title: 'navigation.register',
    description: 'site.subtitleSite',
  },
  '/auth/request-password': {
    title: 'auth.recoverPassword',
  },
  '/invite/': {
    title: 'person.invite.autoregister',
  },
};
