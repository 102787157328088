import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-select-input',
  template: `
    <div class="form-group">
      <label class="label" *ngIf="label">
        {{ label | translate }}
        {{ required ? "*" : "" }}</label>
      <select
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        [required]="required"
        [disabled]="disabled"
        class="form-control"
        placeholder="'actions.select' | translate"
        >
        <option *ngFor="let option of options" [value]="option.value">
          {{ option.label | translate }}
        </option>
      </select>
    </div>
  `,
})
export class SelectInputComponent implements OnInit {
  @Input() label: string;
  @Input() value: any;
  @Input() options: any[];
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: any;

  ngOnInit() {
    this.selectedValue = this.value; // Define o valor inicialmente selecionado no componente
  }

  onValueChange(newValue: any) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
