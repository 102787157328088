import { Component, OnInit } from '@angular/core';
import {ThemeConfigService} from '../../../@theme/services/theme-config.service';

@Component({
  selector: 'ngx-logo-menu',
  template: `
    <div class="logo clearfix pb-0">
    <a class="logo-text text-left">
      <div class="logo-img">
              <img
                class="imgLogo text text-center"
                [src]="logo"
              />
            </div>
          </a>
    </div>
  `,
  styleUrls: ['./logo-menu.layout.scss'],
})
export class LogoMenuComponent implements OnInit {
  logo: string;
  isMenu = true;

  constructor(
    private theme: ThemeConfigService,
  ) {}

  ngOnInit() {
    const skinData = this.theme.getSkinData();

    if (skinData) {
      this.logo = this.theme.getImageBase64(skinData.logo);
    }
  }
}
