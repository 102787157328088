import {Person, PersonDto} from '../model/person.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {Page} from '../../../shared/interface/page';
import {PersonAniversary, PersonLocations} from '../model/personaddress.model';
import {DadosContaService} from '../../../shared/services/dadosconta.service';

const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/person`;

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  private _externalChange = new Subject<void>();

  constructor(
    private http: HttpClient,
    private dadosContaService: DadosContaService,
  ) { }

  getAll(page: number, pageSize: number, filter: any): Observable<Page<Person>> {
    const params = new HttpParams()
      .set('name', filter.toString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<Page<Person>>(`${baseUrl}`, { params });
  }

  get(id: any): Observable<Person> {
    return this.http.get<Person>(`${baseUrl}/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  getAllPersonDto(): Observable<PersonDto[]> {
    return this.http.get<PersonDto[]>(`${baseUrl}/autocomplete`);
  }

  getMyData(): Observable<Person> {
    return this.http.get<Person>(`${baseUrl}/me`);
  }

  updateMyData(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/me`, data);
  }

  generateInviteUrl(): Observable<any> {
    return this.http.get(`${baseUrl}/invite/generate`, {});
  }

  importPersons(Persons: any) {
    return this.http.post(`${baseUrl}/invite/import`, Persons);
  }

  getAllLocations(): Observable<PersonLocations[]> {
    return this.http.get<PersonLocations[]>(`${baseUrl}/report/location`);
  }

  getAllAniversary(): Observable<PersonAniversary[]> {
    return this.http.get<PersonAniversary[]>(`${baseUrl}/report/aniversary`);
  }

  verifyPersonMe(): Observable<any> {
    const data = this.dadosContaService.getPersonMe();
    if (data) {
      return JSON.parse(data);
    }
    this.getMyData().subscribe((res) => {
      this.dadosContaService.savePersonMe(res);
      this._externalChange.next();
      return res;
    });
  }
}
