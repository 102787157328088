import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/public`;

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private http: HttpClient) {}

  getSkin(dominio: string): Observable<any> {
    const url = `${baseUrl}/skin/${dominio}`;
    return this.http.get<any>(url);
  }

  getSkinByDomain(dominio: string): Observable<any> {
    const url = `${baseUrl}/configskin/${dominio}`;
    return this.http.get<any>(url);
  }
}
