import {Component, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Person} from '../../pages/person/model/person.model';
import {TenantList} from '../models/tenant-list.model';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NotificationService} from '../../shared/services/notificationService.service';
import {TenantListService} from '../services/tenant-list.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  nameAccountCtl = new FormControl();
  options?: { name_conta: string | undefined; id: string | undefined; tenant_name: string | undefined }[] = [];

  searchTerm: any;
  listTenant?: TenantList[];
  selectedAccount: any;
  nameAccount: string | undefined;
  mostrarMensagem: boolean = false;

  @Input() personData: Person = {
    name: '',
    email: '',
    phone: '',
    roles: 2,
    birthAt: null,
    createAccess: false,
    sex: '',
  };
  loading: boolean = false;
  linkUrl1 = 'https://kirkdigital.cloud/pages/terms-of-use';
  linkUrl2 = 'https://kirkdigital.cloud/pages/privacy-policy';

  isFormValid: boolean = false;
  acceptTerms: boolean = false;
  resolvedCaptcha: boolean | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private tenantListService: TenantListService,
    private notificar: NotificationService,
    private auth: AuthService,
  ) {

  }


  saveUser() {
    if (this.personData.name === '' || this.personData.email === '' || this.personData.phone === '') {
      this.notificar.showWarning('message.required');
      return;
    }

    if (this.selectedAccount === null || this.selectedAccount === undefined) {
      this.notificar.showWarning('actions.selectAccount');
      return;
    }

    const data = {
      name: this.personData.name,
      email: this.personData.email,
      phone: this.personData.phone,
    };

    this.loading = true;


    this.auth.register(data, this.selectedAccount.tenant_name).subscribe({
      next: (response) => {
        this.personData.name = '';
        this.personData.email = '';
        this.personData.phone = '';
        this.selectedAccount = null;
        this.nameAccount = '';
        this.loading = false;
        this.confirmSave(response.message);
        },
      error: () => {
        this.loading = false;
        this.notificar.showErroInesperado();
      },
    });
  }

  confirmSave(swalTitle?: string) {
      Swal.fire({
        title: this.notificar.getTraduzir('updated_successfully'),
        text: this.notificar.getTraduzir(swalTitle),
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: this.notificar.getTraduzir('sweetalert.yes')}).then((result) => {
        if (result.value) {
          this.router.navigateByUrl('/').then(r => r);
        }
      });
  }


  onSearchChange() {
    this.loading = true;
    this.tenantListService.getAll(this.searchTerm).subscribe({
      next: (data) => {
        this.listTenant = data;
        this.loading = false;
        this.options = this.listTenant?.map((tenant) => {
          return {
            name_conta: tenant.name_conta,
            id: tenant.id,
            tenant_name: tenant.tenant_name,
          };
        });
        if (this.listTenant.length === 0) {
          this.mostrarMensagem = true;
          return;
        }

        this.mostrarMensagem = false;
      },
      error: () => {
        this.notificar.showErroInesperado();
      },
    });
  }


  onEnterClick(event: any) {
    this.nameAccount = event.name_conta;
    this.selectedAccount = event;
  }

  resolved(captchaResponse: string) {
    this.resolvedCaptcha = true;
    this.updateFormValidity();
  }

  updateFormValidity() {
    this.isFormValid = this.resolvedCaptcha && this.acceptTerms;
  }
}
