import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';

@Component({
  selector: 'ngx-radio-input',
  template: `<label class="label">
    {{ label | translate }}
    {{ required ? "*" : "" }}</label>
    <div class="demo-items">
      <label *ngFor="let option of options" class="radio-label">
        <input
          class="radio-input mr-2 ml-2"
          type="radio"
          [value]="option.value"
          [name]="name"
          [checked]="option.value === selectedValue"
          (change)="onValueChange(option.value)"
          [disabled]="disabled"
        />
        <i
          [ngClass]="iconMap[option.value]"
          class="radio-icon mr-1"
          *ngIf="falseIcon"
        ></i>
        {{ option.label | translate }}
      </label>
    </div> `,
})
export class RadioInputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label: string;
  @Input() value: any;
  @Input() options: any[];
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() falseIcon: boolean = true;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: any;

  iconMap = {
    m: 'bi bi-gender-male',
    f: 'bi bi-gender-female',
  };

  ngOnInit() {
    this.updateSelectedValue();
  }

  ngAfterViewInit() {
    this.updateSelectedValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.updateSelectedValue();
    }
  }

  updateSelectedValue() {
    this.selectedValue = this.value ? this.value : this.options[0].value;

    // Encontrar a opção com o valor selecionado e marcar como selecionada
    const selectedOption = this.options.find(
      (option) => option.value === this.selectedValue);
    if (selectedOption) {
      selectedOption.selected = true;
    }
  }

  onValueChange(newValue: any) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
