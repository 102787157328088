import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {UserTenant} from '../../../../../core/accounts/model/usertenant.model';
import {Subscription} from 'rxjs';
import {UserTenantListService} from '../../../../../core/accounts/service/user-tenant-list.service';

@Component({
  selector: 'ngx-dialog-account',
  templateUrl: './dialog-account.component.html',
  styleUrls: ['./dialog-account.component.scss'],
})
export class DialogAccountComponent implements OnDestroy {
  selectedAccount: any;
  private subscription: Subscription = new Subscription();

  userTenant: UserTenant = {
    tenantId: '',
    userId: '',
    personId: '',
  };

  constructor(
    public dialogRef: MatDialogRef<DialogAccountComponent>,
    private userTenantListService: UserTenantListService,
    @Inject(MAT_DIALOG_DATA) public data:
    {
      userTenantList$: any;
      selectedAccount: any;
      usuario: {
        userId: string;
        roles: string;
      };
    },
  ) {
  }

  // Método chamado quando uma conta é selecionada no dropdown
  accountSelected(event: any) {
    this.selectedAccount = event;
    this.updateAccount();
  }

  // Atualiza a conta no serviço quando uma nova conta é selecionada
  private updateAccount() {
    if (this.selectedAccount) {
      this.userTenant.tenantId = this.selectedAccount.tenantId;
      this.userTenant.userId = this.data.usuario.userId;
      this.userTenant.personId = this.selectedAccount.personId;
      this.userTenantListService.setConta(
        this.userTenant,
        this.selectedAccount.name_conta,
      );
    }
    this.dialogRef.close(this.selectedAccount);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search(value: any) {
    this.data.userTenantList$ = this.userTenantListService.getStoredData().pipe(
      map((userTenantList) =>
        userTenantList.filter((account) =>
          account.name_conta.toLowerCase().includes(value.toLowerCase()),
        ),
      ),
    );
  }
}
