import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'ngx-input-field',
  template: `<div class='form-group'>
    <label class='label'>{{ label | translate }} {{ required ? '*' : '' }}</label>
    <input
      [ngModel]='value'
      (input)='onInputChange($event.target.value); setStatus()'
      [required]='required'
      [placeholder]='(placeholder | translate) || label | translate'
      [disabled]='disabled'
      nbInput
      fullWidth
      [maxLength]='maxLength'
      class='form-control'
      ngModel
      [type]='type'
      [ngClass]="{
        'status-warning': status === 'warning',
        'status-danger': status === 'danger'
      }"
    />
  </div>`,
})
export class InputFieldComponent implements OnChanges {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() type: string = 'text';
  @Input() maxLength: number = 255;
  status: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.setStatus();
    }
  }

  setStatus() {
    if (this.required && (!this.value || this.value.trim() === '')) {
      this.status = 'danger';
    } else {
      this.status = '';
    }
  }
}
