import { Injectable } from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {EncryptService} from './encrypt.service';

const USER_KEY = 'user_instance';
const ACCOUNT_KEY = 'name_account';
const PERMISSION_KEY = 'permission_instance';
const MULTITENANT_KEY = 'multitenant';
const CONFIG_KEY = 'configData';
const PRECADASTRO_KEY = 'precadastroCache';
const ONBORDING_KEY = 'onbordingCache';
const PERSONME_KEY = 'personCache';

@Injectable({
  providedIn: 'root',
})
export class DadosContaService {
  _sessionStorage = window.sessionStorage;

  constructor(
    private permissionsService: NgxPermissionsService,
    private encryptService: EncryptService,
  ) {
  }

  public saveDadosAccount(dados: any): void {
    this._sessionStorage.removeItem(ACCOUNT_KEY);
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(dados));
    this._sessionStorage.setItem(ACCOUNT_KEY, dadosCriptografados);
  }

  public getDadosAccount(): any {
    const key = this._sessionStorage.getItem(ACCOUNT_KEY);
    if (key) {
      const dadosDescriptografados = this.encryptService.decryptData(key);
      return JSON.parse(dadosDescriptografados);
    }
    return '';
  }

  public saveDadosMultitenant(dados: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(dados));
    this._sessionStorage.setItem(MULTITENANT_KEY, dadosCriptografados);
  }

  public userIsMultitenant(): any {
    const user = this._sessionStorage.getItem(MULTITENANT_KEY);
    if (user) {
      const dadosDescriptografados = this.encryptService.decryptData(user);
      return JSON.parse(dadosDescriptografados);
    }

    return null;
  }

  public saveDadosUser(user: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(user));
    this._sessionStorage.setItem(USER_KEY, dadosCriptografados);
  }

  public getDadosUser(): any {
    const user = this._sessionStorage.getItem(USER_KEY);
    if (user) {
      const dadosDescriptografados = this.encryptService.decryptData(user);
      return JSON.parse(dadosDescriptografados);
    }

    return null;
  }

  public savePersonMe(user: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(user));
    this._sessionStorage.setItem(PERSONME_KEY, dadosCriptografados);
  }
  public getPersonMe(): any {
    const user = this._sessionStorage.getItem(PERSONME_KEY);
    if (user) {
      const dadosDescriptografados = this.encryptService.decryptData(user);
      return JSON.parse(dadosDescriptografados);
    }
    return null;
  }

  public saveDadosPermission(permission: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(permission));
    this._sessionStorage.setItem(PERMISSION_KEY, dadosCriptografados);
  }

  public getDadosPermission(): any {
    const permission = this._sessionStorage.getItem(PERMISSION_KEY);
    if (permission) {
      const dadosDescriptografados = this.encryptService.decryptData(permission);
      return JSON.parse(dadosDescriptografados);
    }

    return null;
  }

  // Método para carregar as permissões do armazenamento local
  public loadUserPermissions(): any {
    // Obter permissões do armazenamento local
    const tratardado = this.getDadosPermission();
    // tratar permissao e adicionar no addPermission

    const newTratamento = [];
    // tratar permissao para array
    if (tratardado) {
      for (const permissao of tratardado) {
        if (permissao.criar) {
          newTratamento.push(permissao.features + '_CRIAR');
        }
        if (permissao.ler) {
          newTratamento.push(permissao.features + '_LER');
        }
        if (permissao.atualizar) {
          newTratamento.push(permissao.features + '_ATUALIZAR');
        }
        if (permissao.excluir) {
          newTratamento.push(permissao.features + '_EXCLUIR');
        }
      }
    }

    this.permissionsService.loadPermissions(newTratamento);
  }

  public getAllPermissions(): any[] {
    this.loadUserPermissions();
    return Object.values(this.permissionsService.getPermissions());
  }

  public getPermissionsForFeature(feature: string): any {
    this.loadUserPermissions();

    const permissions_criar = this.permissionsService.getPermission(feature + '_CRIAR');
    const permissions_ler = this.permissionsService.getPermission(feature + '_LER');
    const permissions_atualizar = this.permissionsService.getPermission(feature + '_ATUALIZAR');
    const permissions_excluir = this.permissionsService.getPermission(feature + '_EXCLUIR');

    return {
      criar: permissions_criar ? true : false,
      ler: permissions_ler ? true : false,
      atualizar: permissions_atualizar ? true : false,
      excluir: permissions_excluir ? true : false,
    };
  }

  public userHasPermission(feature: string): boolean {
    this.loadUserPermissions();
    return this.permissionsService.getPermission(feature) ? true : false;
  }

  public isUserAdmin(): boolean {
    if (this.getPersonMe() === null) {
      return false;
    }

    return this.getPersonMe().role === 'ROLE_INTEGRADOR' ||
      this.getPersonMe().role === 'ROLE_ADMIN' ||
      (this.getPersonMe().role === 'ROLE_USER' && this.getPersonMe().rolesId === 1);
  }

  public clearDados(): void {
    this._sessionStorage.removeItem(CONFIG_KEY);
    this._sessionStorage.removeItem(USER_KEY);
    this._sessionStorage.removeItem(ACCOUNT_KEY);
    this._sessionStorage.removeItem(PERMISSION_KEY);
    this._sessionStorage.removeItem(PRECADASTRO_KEY);
    this._sessionStorage.removeItem(ONBORDING_KEY);
    this._sessionStorage.removeItem(PERSONME_KEY);
    // window.sessionStorage.removeItem(MULTITENANT_KEY);
  }
}



