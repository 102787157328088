<nb-card>
  <nb-card-header>
    Tirar Foto
  </nb-card-header>
  <nb-card-body>
    <video #videoElement autoplay></video>
  </nb-card-body>
  <ngx-card-footer>
    <div class="buttons-container">
      <div class="buttons-row">
        <button nbButton status="primary" (click)="takePhoto()">Tirar Foto</button>
        <button nbButton status="basic" (click)="close()">Fechar</button>
      </div>
    </div>
  </ngx-card-footer>
</nb-card>
