<nb-layout center>
  <nb-layout-column>
    <br>
    <ngx-logo-menu></ngx-logo-menu>
    <ngx-card [loading]="loading">
      <nb-card-header>
        <nb-action>
          <nav class="navigation">
            <a href="#" aria-label="Back" class="link back-link">
              <nb-icon icon="arrow-back-outline" status="primary"></nb-icon>
            </a>
          </nav>
        </nb-action>
      </nb-card-header>
      <nb-card-body>
        <nb-stepper #stepper [linear]="true">
          <nb-step label="{{ 'actions.start' | translate }}">
            <div class="step-container">
              <h3 class="text-center">{{ 'onboarding.title' | translate }}</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="image-container">
                    <img
                      [src]="'assets/images/image-1.png'"
                      [alt]="'onboarding.title' | translate"
                      class="image"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  {{ 'onboarding.subtitle' | translate }}
                  <br>
                  <br>
                  <button nbButton nbStepperNext fullWidth status="primary">
                    {{ 'onboarding.start' | translate }}
                    <nb-icon icon="arrow-forward-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>
          </nb-step>
          <nb-step label="{{ 'actions.searchAccount' | translate }}" [stepControl]="searchForm">
            <form #searchForm="ngForm" class="step-container">
              <h5>{{ 'actions.searchAccount' | translate }}</h5>
              <div class="row">
                <div class="col-md-6">
                  <label class="label">{{ 'auth.selectAccount' | translate }}</label>
                  <div class="input-group">
                    <input type="text"
                           class="form-control"
                           placeholder="{{ 'account.nameInput' | translate }}"
                           name="name"
                           required
                           nbInput
                           ngModel
                           [(ngModel)]="searchTerm"
                           #name="ngModel"
                           [status]="name.invalid ? 'danger' : 'basic'">

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                              (click)="onSearchChange()">
                        {{ 'actions.search' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <nb-list>
                    <nb-list-item *ngFor="let account of options" (click)="onEnterClick(account)"
                                  [class.selected]="account === selectedAccount">
                      <nb-icon icon="layers-outline"></nb-icon>
                      {{ account.name_conta }}
                    </nb-list-item>
                  </nb-list>
                  <div *ngIf="mostrarMensagem" class="alert alert-danger">
                    <nb-icon icon="alert-triangle-outline"></nb-icon>
                    {{ 'auth.notData' | translate }}
                  </div>
                </div>
              </div>
            </form>
            <br><br>
            <div *ngIf="selectedAccount" class="mb-5">
            <button nbButton nbStepperPrevious>{{ 'onboarding.previous' | translate }}</button>
            <button nbButton [disabled]="!selectedAccount" nbStepperNext>{{ 'onboarding.next' | translate }}</button>
            </div>
          </nb-step>
          <nb-step [stepControl]="movieForm" label="Dados">
            <form #movieForm="ngForm" class="step-container">
              <h5>{{ 'auth.inputData' | translate }}</h5>
              <div class="row">
                <div class="col-md-6">
                  <ngx-input-field
                    [label]="'generic.name'"
                    [value]="personData.name"
                    (valueChange)="personData.name = $event"
                    [disabled]="false"
                    [required]="true"
                    type="text"
                    (input)="updateFormValidity()"
                  ></ngx-input-field>
                </div>

                <div class="col-md-6">
                  <ngx-input-field
                    [label]="'generic.email'"
                    [placeholder]="'email@exemple.com'"
                    [value]="personData.email"
                    [required]="true"
                    type="email"
                    (valueChange)="personData.email = $event"
                  ></ngx-input-field>
                </div>
                <div class="form-group col-sm-6">
                  <ngx-phone-input
                    [label]="'generic.phone'"
                    [value]="personData.phone"
                    [required]="true"
                    (valueChange)="personData.phone = $event"
                  ></ngx-phone-input>
                </div>
                <div class="form-group col-sm-6">
                  <re-captcha (resolved)="resolved($event)"
                              siteKey="6LcC-N4eAAAAAMQ-3U0KvZ-1dX6WaNTNHsOvTlAS"></re-captcha>
                </div>
                <div class="form-group col-sm-6">
                  <ngx-checkbox-input
                    [label]="'auth.acceptTermsAndPolicy' | translate: { link1: linkUrl1, link2: linkUrl2 }"
                    [(checked)]="acceptTerms"
                    (checkedChange)="acceptTerms = $event"
                    [required]="true"
                    (change)="updateFormValidity()"
                    [(ngModel)]="acceptTerms"
                  ></ngx-checkbox-input>
                </div>
              </div>
              <button nbButton nbStepperPrevious>{{ 'onboarding.previous' | translate }}</button>
              <button nbButton [disabled]="!personData.name || !personData.email || !personData.phone || !resolvedCaptcha || !acceptTerms"
                      nbStepperNext>{{ 'onboarding.next' | translate }}</button>
            </form>
          </nb-step>

          <nb-step label="{{ 'onboarding.finish2' | translate }}">
            <div class="step-container">
              <mat-card-content class="text-center">
                <p> {{'auth.confirmSend' | translate }}.</p>
                <mat-list>
                  <mat-list-item>
                    <nb-icon icon="layers-outline"></nb-icon>
                    <span>{{ 'account.title' | translate }}</span>: {{ this.nameAccount ?? 'actions.selectAccount' | translate }}
                  </mat-list-item>
                  <mat-list-item>
                    <nb-icon icon="person-outline"></nb-icon>
                    <span>{{ 'generic.name' | translate }}</span>: {{ this.personData.name }}
                  </mat-list-item>
                  <mat-list-item>
                    <nb-icon icon="email-outline"></nb-icon>
                    <span>{{ 'generic.email' | translate }}</span>: {{ this.personData.email }}
                  </mat-list-item>
                  <mat-list-item>
                    <nb-icon icon="phone-outline"></nb-icon>
                    <span>{{ 'generic.phone' | translate }}</span>: {{ this.personData.phone }}
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
              <button nbButton nbStepperPrevious>{{ 'onboarding.previous' | translate }}</button>
              <button nbButton (click)="saveUser()"
                      [disabled]="!isFormValid || !acceptTerms || !resolvedCaptcha"
                      status="success">{{ 'onboarding.conclude' | translate }}</button>
            </div>
          </nb-step>
        </nb-stepper>
      </nb-card-body>
    </ngx-card>
  </nb-layout-column>
</nb-layout>
