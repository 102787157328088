import {Component, Input} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-card',
  template: `
      <nb-card
              accent="{{color}}"
              [nbSpinner]="loading"
              [nbSpinnerStatus]="color"
              nbSpinnerSize="giant">
          <ng-content></ng-content>
      </nb-card>
  `,
})
export class CardComponent {
  @Input() loading: boolean = false;
  @Input() title: string = '';
  @Input() color: string = 'primary';

  constructor(
    private titleService: Title,
  ) {
    // this.titleService.setTitle(this.title);
  }

}
