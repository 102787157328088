import {
  Component,
  EventEmitter,
  Output,
  ElementRef,
  NgZone,
  OnInit, Input, ViewChild,
} from '@angular/core';
import * as intlTelInput from 'intl-tel-input';
import * as intlTelInputUtils from 'intl-tel-input/build/js/utils';

@Component({
  selector: 'ngx-phone-input',
  template: `<div class="form-group select-wrapper">
    <label for="phoneInput" class="label label-default"
    >{{ label | translate }}
      {{ required ? "*" : "" }}
    </label
    >
    <input
      class="form-control"
      [disabled]="disabled"
      [required]="required"
      [ngModel]="value"
      type="tel" id="phone-input"
      (input)="changeValue($event.target.value)"
      (ngModelChange)="valueChange.emit($event)"
      nbInput
      fullWidth
      [ngClass]="{
        'status-success': status === 'success',
        'status-danger': status === 'danger'
      }"
    />
    <span class="caption-2" *ngIf="messagePhone">{{ 'phone.NessageWhatsapp' | translate }}</span>
    <span id="valid-msg" class="hide" style="color: green;" *ngIf="isValidNumber">✓ {{ 'phone.ValidNumber' | translate }}</span>
    <span id="error-msg" class="hide" *ngIf="!isValidNumber">{{ messageError | translate }}</span>
  </div>`,
  styleUrls: ['./phone-input.component.css'],
})
export class PhoneInputComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() messagePhone: boolean = false;

  private iti: any; // Declare uma variável para armazenar o objeto intlTelInput
  isValidNumber: boolean;
  status: string;
  errorCode: any;
  errorMap = ['phone.InvalidNumber', 'phone.InvalidCountryCode', 'phone.TooShort', 'phone.TooLong', 'phone.InvalidNumber'];
  messageError: string;

  @ViewChild('phoneInput', { static: true }) phoneInput: ElementRef;
  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  changeValue(value: string) {
    this.valueChange.emit(this.iti.getNumber());

    if (this.iti.isValidNumber()) {
      this.isValidNumber = true;
      this.status = 'success';
    } else {
      this.errorCode = this.iti.getValidationError();
      this.isValidNumber = false;
      this.status = 'danger';
      this.messageError = this.errorMap[this.errorCode];
    }
  }


  getFullNumber(): string {
    if (this.iti) {
      return this.iti.getNumber();
    }
    return ''; // Retorna uma string vazia se o objeto iti não estiver definido
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      const inputElement = this.elementRef.nativeElement.querySelector('#phone-input');

      this.iti = intlTelInput(inputElement, {
        initialCountry: 'br',
        preferredCountries: ['br'],
        separateDialCode: true,
        hiddenInput: 'full_phone',
      });

      inputElement.addEventListener('countrychange', () => {
        this.changeValue(inputElement.value);
      });

      // Inicialize o número formatado no formato internacional
      this.iti.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
    });
  }
}
