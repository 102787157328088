import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NotificationService} from '../../services/notificationService.service';

@Injectable({
  providedIn: 'root',
})
export class CarregamentoService {
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  private loadingMessageSubject = new BehaviorSubject<string>('');
  loadingMessage$ = this.loadingMessageSubject.asObservable();

  constructor(
    private notificar: NotificationService,
  ) {}

  show(message: string = 'Carregando...') {
    this.loadingMessageSubject.next(this.notificar.getTraduzir(message));
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
  }
}
