import { Injectable } from '@angular/core';
import { DadosContaService } from './dadosconta.service';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  constructor(private dadosContaService: DadosContaService) {}

  isXtenantIDSet(): boolean {
    const xtenantID = this.dadosContaService.getDadosUser();
    return !!xtenantID; // Retorna true se o xtenantID estiver definido, caso contrário, retorna false.
  }
}
