import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/services/auth-guard.service';
import {RegisterComponent} from './auth/register/register.component';
import {RegisterInviteComponent} from './auth/registerInvite/register-invite.component';
import {InstallPageComponent} from './auth/install/installPage.component';
import {RegisterIntegradorComponent} from './auth/registerIntegrador/register-integrador.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/sitepages.module').then((m) => m.AuthPagesModule),
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'invite/:token',
    component: RegisterInviteComponent,
  },
  {
    path: 'install',
    component: InstallPageComponent,
  },
  {
    path: 'register-institution',
    component: RegisterIntegradorComponent,
  },
  {
    path: 'site',
    loadChildren: () =>
      import('./site/sitepages.module').then((m) => m.SitePagesModule),
  },
  {
    path: 'core',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/corepages.module').then((m) => m.CorePagesModule),
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];


const config: ExtraOptions = {
  /*ocultar em # na url*/
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
