import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { DadosContaService } from '../../../shared/services/dadosconta.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();
  user: {};
  name_account: string;
  multitenant: boolean = false;
  ocultarMydata: boolean = false;

  userMenu = [] as any[];
  @Output() toggleBack = new EventEmitter<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private dadosContaService: DadosContaService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.name_account = this.dadosContaService.getDadosAccount();
    this.multitenant = this.dadosContaService.userIsMultitenant();
    this.userMenu = [
      { title: 'actions.alterPassword', link: '/main/alter-password', icon: 'lock-outline' },
      { title: 'actions.logout', link: '/auth/logout', icon: 'log-out-outline' },
    ];

    if (this.multitenant === true) {
      this.userMenu.unshift({ title: 'actions.alterAccount', link: '/core/account', icon: 'layers-outline' });
      this.userMenu.unshift({ title: 'navigation.myData', link: '/main/my-data', icon: 'person-outline' });
    }
  }

  onClick(event: any) {
    this.router.navigate([event.link]).then(r => r);
  }

  ngAfterViewInit() {
    this.ocultarMydata = this.dadosContaService.getDadosUser().roles === 'ROLE_USER';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar() {
    this.toggleBack.emit();
  }
}
