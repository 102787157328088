import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';


const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/onboarding-progress`;

@Injectable({
  providedIn: 'root',
})
export class OnbordingService {
  _sessionStorage = window.sessionStorage;
  private _externalChange = new Subject<void>();

  constructor(private http: HttpClient) { }

  get(): any {
    return this.http.get(`${baseUrl}`);
  }

  update(data: any): any {
    this._sessionStorage.setItem('onbordingCache', JSON.stringify(data));
    return this.http.patch(`${baseUrl}`, data);
  }

  get externalChange(): Observable<void> {
    return this._externalChange.asObservable();
  }

  verify() {
    const data = this._sessionStorage.getItem('onbordingCache');
    if (data) {
      return JSON.parse(data);
    } else {
      this.get().subscribe((response: any) => {
        this._sessionStorage.setItem('onbordingCache', JSON.stringify(response));
        this._externalChange.next();
        return response;
      });
    }
  }
}
