<div class="header-container">
  <!-- Logo à esquerda -->
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
  </div>

  <!-- Itens à direita -->
  <div class="header-right-container">
    <nb-actions size="small">
      <nb-action>
        <ngx-language-selector></ngx-language-selector>
      </nb-action>
    </nb-actions>
    <nb-action class="control-item" icon="log-out-outline" (click)="logout()"></nb-action>
  </div>
</div>
