import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly LANG_KEY = 'selectedLanguage';

  constructor(private translate: TranslateService) {}

  getSelectedLanguage(): string {
    return localStorage.getItem(this.LANG_KEY) || this.translate.getBrowserLang() || 'pt';
  }

  setSelectedLanguage(language: string): void {
    localStorage.setItem(this.LANG_KEY, language);
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

}
