import { Component } from '@angular/core';
import {
  NbSidebarService,
} from '@nebular/theme';

import { LayoutService } from '../../utils';
import {ThemeConfigService} from '../../services/theme-config.service';

@Component({
  selector: 'ngx-account-column-layout',
  styleUrls: ['./account-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header subheader class="nb-custom-layout-header">
            <ngx-header-account></ngx-header-account>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        responsive
        containerFixed="true"
      >
        <div class="sidebar-header">
          <div class="logo clearfix pb-0">
            <a class="logo-text text-left">
              <div class="logo-img">
                <img
                  class="imgLogo text text-center logo d-none d-sm-none d-md-none d-lg-block d-xl-block"
                  [src]="isMenuCompact ? imagemLogoMinimal : imagemLogo"
                  width="100"
                height="50"
                />
                <img
                  class="imgLogo mini text text-center logo d-block d-sm-block d-md-block d-lg-none d-xl-none"
                  src="assets/logo.png"
                  width="50"
                height="25"
                />
              </div>
            </a>
          </div>
        </div>
        <!-- Close button -->
        <button class="close-button" (click)="toggleSidebar()">
          <nb-icon [icon]="isResponsiveMenuVisible ? 'toggle-left-outline' : 'toggle-right-outline'" class="text-dark">
          </nb-icon>
        </button>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="colored-column-basic">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class AccountColumnLayoutComponent {
  isResponsiveMenuVisible: boolean = true;
  isMenuCompact: boolean = false;

  imagemLogo: string = 'assets/logo.png';
  imagemLogoMinimal: string = 'assets/logoMinimal.png';

  constructor(
    private sidebarService: NbSidebarService,
    private theme: ThemeConfigService,
    private layoutService: LayoutService) {
    const skinData = this.theme.getSkinData();

    if (skinData) {
      this.imagemLogo = this.theme.getImageBase64(skinData.logo) || this.imagemLogo;
      this.imagemLogoMinimal = this.theme.getImageBase64(skinData.logoMinimal) || this.imagemLogoMinimal;
    }
  }

  toggleSidebar(): void {
    this.sidebarService.toggle(this.isResponsiveMenuVisible, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    this.isResponsiveMenuVisible = !this.isResponsiveMenuVisible;
    this.isMenuCompact = !this.isMenuCompact;
  }

}
