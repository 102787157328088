import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NotificationService} from '../../shared/services/notificationService.service';
import Swal from 'sweetalert2';
import {Integrador} from '../../core/institutions/model/Integrador.model';

@Component({
  selector: 'ngx-register-invite',
  templateUrl: './register-integrador.component.html',
  styleUrls: ['./register-integrador.component.scss'],
})
export class RegisterIntegradorComponent {
  @Input() data: Integrador = {
    name: '',
    email: '',
    mobile: '',
    doc: '',
    inadiplente: false,
    license: 1,
  };

  linkUrl1 = 'https://kirkdigital.cloud/pages/terms-of-use';
  linkUrl2 = 'https://kirkdigital.cloud/pages/privacy-policy';

  isFormValid: boolean = false;
  acceptTerms: boolean = false;
  nameAccount: string = '';
  loading: boolean = false;
  resolvedCaptcha: boolean | null = null;

  constructor(
    private router: Router,
    private notificar: NotificationService,
    private auth: AuthService,
  ) {
  }

  updateFormValidity() {
    const isFormValid =
      (this.data.name && this.data.name.trim() !== '') &&
      (this.data.email && this.data.email.trim() !== '') &&
      (this.data.mobile && this.data.mobile.trim() !== '') &&
      (this.data.doc && this.data.doc.trim() !== '') &&
      this.acceptTerms;
    // Emitir o resultado
    this.isFormValid = isFormValid;
  }


  saveUser() {
    if (!this.acceptTerms) {
      this.notificar.showWarning('message.termsAndPolicy');
      return;
    }

    if (this.data.name === '' || this.data.email === '' || this.data.mobile === '') {
      this.notificar.showWarning('message.required');
      return;
    }

    this.loading = true;

    const data = {
      name: this.data.name,
      email: this.data.email,
      mobile: this.data.mobile,
      doc: this.data.doc,
    };

    this.auth.registerIntegrador(data).subscribe({
      next: (response) => {
        this.data.name = '';
        this.data.email = '';
        this.data.mobile = '';
        this.data.doc = '';
        this.loading = false;
        this.confirmSave(response.message);
      },
      error: (error) => {
        this.loading = false;
        this.notificar.showWarning(error.error.message);
      },
    });
  }

  confirmSave(swalTitle?: string) {
    Swal.fire({
      title: this.notificar.getTraduzir('updated_successfully'),
      text: this.notificar.getTraduzir(swalTitle),
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: this.notificar.getTraduzir('sweetalert.yes')}).then((result) => {
      if (result.value) {
        this.router.navigateByUrl('/').then(r => r);
      }
    });
  }

  resolved(captchaResponse: string) {
    this.resolvedCaptcha = true;
    this.updateFormValidity();
  }
}
