<div class="header-container">
  <!-- Logo à esquerda -->
  <div class="logo-container">
    <div (click)="toggleSidebar()" class="sidebar-toggle">
      <nb-icon icon="menu-outline"></nb-icon>
    </div>
  </div>
  <div class="header-right-container">
    <!-- Itens à direita -->
    <ngx-account-selector
      [isMultitenant]="multitenant"
    ></ngx-account-selector>
    <ngx-language-selector></ngx-language-selector>
    <button mat-icon-button  [matMenuTriggerFor]="menu">
      <nb-icon icon="person-outline" size="tiny"></nb-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div *ngFor="let menu of userMenu">
        <button mat-menu-item (click)="onClick(menu)">
          <nb-icon [icon]="menu.icon"></nb-icon>
          {{menu.title | translate}}</button>
      </div>
    </mat-menu>
  </div>
</div>
