import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges, OnInit,
} from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'ngx-data-input',
  template: `
    <label class="label">
      {{ label | translate }} {{ required ? "*" : "" }}</label>
    <input
      nbInput
      [(ngModel)]="value"
      (ngModelChange)="onInputChange($event)"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder ? placeholder : placeholderDate"
      [nbDatepicker]="formpicker"
      class="form-control"
      name="dataInputName"
    />
    <nb-datepicker #formpicker ></nb-datepicker>
  `,
})
export class DataInputComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder: string = 'Aug 15, 2000';
  @Input() disabled: boolean;
  @Input() required: boolean;
  status: string;
  placeholderDate: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private datePipe: DatePipe) { }

  onInputChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }

  ngOnInit() {
    const currentDate = new Date();
    this.placeholderDate = this.datePipe.transform(currentDate, 'MMM dd, yyyy');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.setStatus();
    }
  }

  setStatus() {
    if (this.required && !this.value) {
      this.status = 'danger';
    } else {
      this.status = '';
    }
  }
}
