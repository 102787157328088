import {
  Component,
  Input,
  Output,
  EventEmitter, OnChanges,
} from '@angular/core';
@Component({
  selector: 'ngx-checkbox-input',
  template: `
    <div class="checkbox-container" [ngClass]="{ 'form-group': ignoreClass }">
      <label class="checkbox-label">
        <nb-checkbox
          type="checkbox"
          [checked]="checked"
          (change)="onCheckboxChange($event.target.checked)"
          [disabled]="disabled"
          [title]="title ? (title | translate) : ('generic.active' | translate)"
        >
        <div [innerHTML]="label | translate"></div>
        </nb-checkbox> <span class="label " *ngIf="required"> *</span>
      </label>
    </div>
  `,
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnChanges {
  @Input() label: string;
  @Input() checked: boolean = false;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() title: string;
  @Input() ignoreClass: boolean;
  status: string;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onCheckboxChange(checked: boolean) {
    this.checked = checked;
    this.checkedChange.emit(checked);
  }

  ngOnChanges() {
    this.checkedChange.emit(this.checked);
  }
}
