import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {ConfigSystem} from '../model/ConfigSystem';
import {EncryptService} from '../../../shared/services/encrypt.service';

const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/configuration/`;
const CONFIG_KEY = 'configData';


@Injectable({
  providedIn: 'root',
})
export class ConfigSystemService {

  constructor(private http: HttpClient, private encryptService: EncryptService) { }

  get(): Observable<ConfigSystem> {
    return this.http.get<ConfigSystem>(baseUrl);
  }

  save(data: any): Observable<any> {
    return this.http.put(baseUrl, data);
  }

  init(): Observable<any> {
    if (!this.getConfigConta()) {
      this.get().toPromise().then((data) => {
        if (data) {
          this.saveConfigConta(data);
        }
      });
    }
    return this.getConfigConta();
  }

  public saveConfigConta(Settings: any): void {
    const dadosCriptografados = this.encryptService.encryptData(JSON.stringify(Settings));
    window.sessionStorage.setItem(CONFIG_KEY, dadosCriptografados);
  }

  public getConfigConta(): any {
    const Settings = window.sessionStorage.getItem(CONFIG_KEY);
    if (Settings) {
      const dadosDescriptografados = this.encryptService.decryptData(Settings);
      return JSON.parse(dadosDescriptografados);
    }
    return null;
  }

  public formatCurrencyValue(value: number): string {
    const configCampos = this.getConfigConta();
    switch (configCampos.currency) {
      case 'BRL':
        return `R$ ${value.toLocaleString('pt-BR')}`;
      case 'USD':
        return `US$ ${value.toLocaleString('en-US')}`;
      case 'EUR':
        return `€ ${value.toLocaleString('de-DE')}`;
      default:
        return value.toLocaleString('pt-BR');
    }
  }
}
