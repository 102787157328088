import {Component, Input} from '@angular/core';
import {InviteUrl, Person} from '../../pages/person/model/person.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NotificationService} from '../../shared/services/notificationService.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngx-register-invite',
  templateUrl: './register-invite.component.html',
  styleUrls: ['./register-invite.component.scss'],
})
export class RegisterInviteComponent {
  @Input() personData: Person = {
    name: '',
    email: '',
    phone: '',
    birthAt: null,
    image: '',
    sex: '',
  };

  linkUrl1 = 'https://kirkdigital.cloud/pages/terms-of-use';
  linkUrl2 = 'https://kirkdigital.cloud/pages/privacy-policy';

  inviteToken: InviteUrl = {};
  isFormValid: boolean = false;
  acceptTerms: boolean = false;
  nameAccount: string = '';
  loading: boolean = true;
  resolvedCaptcha: boolean | null = null;

  constructor(
    private router: Router,
    private notificar: NotificationService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {
    this.inviteToken.inviteToken = this.route.snapshot.params.token;
    this.getConsultInvite();
  }

  getConsultInvite() {
    this.loading = true;
    this.auth.consultarTokenInvite(this.inviteToken.inviteToken).subscribe(
      (response) => {
        this.nameAccount = response.nameTenant;
        this.loading = false;
      },
      () => {
        this.notificar.showError('message.invalidToken');
        setTimeout(() => {
          this.router.navigateByUrl('/').then(r => r);
        }, 3000);
      });
  }

  updateFormValidity() {
    const isFormValid =
      (this.personData.name && this.personData.name.trim() !== '') &&
      (this.personData.email && this.personData.email.trim() !== '') &&
      (this.personData.phone && this.personData.phone.trim() !== '') &&
      this.acceptTerms;
    // Emitir o resultado
    this.isFormValid = isFormValid;
  }


  saveUser() {
    if (!this.acceptTerms) {
      this.notificar.showWarning('message.termsAndPolicy');
      return;
    }

    if (this.personData.name === '' || this.personData.email === '' || this.personData.phone === '') {
      this.notificar.showWarning('message.required');
      return;
    }

    const data = {
      name: this.personData.name,
      email: this.personData.email,
      phone: this.personData.phone,
      birthAt: this.personData.birthAt,
      sex: this.personData.sex,
    };

    this.auth.registerInvite(data, this.inviteToken.inviteToken).subscribe({
      next: (response) => {
        this.personData.name = '';
        this.personData.email = '';
        this.personData.phone = '';
        this.personData.birthAt = null;
        this.confirmSave(response.message);
        },
      error: () => {
        this.notificar.showErroInesperado();
      },
    });
  }

  confirmSave(swalTitle?: string) {
    Swal.fire({
      title: this.notificar.getTraduzir('updated_successfully'),
      text: this.notificar.getTraduzir(swalTitle),
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: this.notificar.getTraduzir('sweetalert.yes')}).then((result) => {
      if (result.value) {
        this.router.navigateByUrl('/').then(r => r);
      }
    });
  }

  resolved(captchaResponse: string) {
    this.resolvedCaptcha = true;
    this.updateFormValidity();
  }
}
