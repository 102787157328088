export function getTypeCardOptions() {
  return [
    {value: 'VISA', label: 'Visa Card'},
    {value: 'MASTERCARD', label: 'Master Card'},
    {value: 'ELO', label: 'Elo Card'},
    {value: 'OUTRO', label: 'paymentCategory.OUTROS'}];
}

export function getTypeAccountOptions() {
  return [
    {value: 'BANK_ACCOUNT', label: 'paymentType.CONTA_BANCARIA'},
    {value: 'CREDIT_CARD', label: 'paymentType.CARTAO'}];
}

export function getTypeCalendarOptions() {
  return [
    {value: false, label: 'calendar.event'},
    {value: true, label: 'calendar.agenda'}];
}


export function getSexOptions() {
  return [
    {value: 'm', label: 'gender.male'},
    {value: 'f', label: 'gender.female'}];
}

export function getStatusOptions() {
  return [
    {value: 'true', label: 'generic.active'},
    {value: 'false', label: 'generic.inactive'}];
}

export function getSituationOptions() {
  return [
    {value: '1', label: 'muralrecado.situation1'},
    {value: '2', label: 'muralrecado.situation2'},
    // { value: '3', label: 'muralrecado.situation3' },
    {value: '4', label: 'muralrecado.situation4'}];
}

export function getTypeEntradaOptions() {
  return [
    {label: 'paymentCategory.DIZIMO', value: 'DIZIMO'},
    {label: 'paymentCategory.OFERTA', value: 'OFERTA'},
    {label: 'paymentCategory.OUTROS', value: 'OUTROS'}];
}

export function getTypeSaidaOptions() {
  return [
    {label: 'paymentCategory.DESPESA', value: 'DESPESA'},
    {label: 'paymentCategory.DOACAO', value: 'DOACAO'},
    {label: 'paymentCategory.OUTROS', value: 'OUTROS'}];
}

export function getOperationOptions() {
  return [
    {label: 'financial.ENTRADA', value: 'ENTRADA'},
    {label: 'financial.RETIRADA', value: 'RETIRADA'},
    {label: 'financial.TRANSFERENCIA', value: 'TRANSFERENCIA'}];
}

export function getPaymentOptions() {
  return [
    {label: 'paymentType.DINHEIRO', value: 'DINHEIRO'},
    {label: 'paymentType.PIX', value: 'PIX'},
    {label: 'paymentType.CARTAO_CREDITO', value: 'CARTAO_CREDITO'},
    {label: 'paymentType.CARTAO_DEBITO', value: 'CARTAO_DEBITO'},
    {label: 'paymentType.BOLETO', value: 'BOLETO'},
    {label: 'paymentType.TRANSFERENCIA', value: 'TRANSFERENCIA'},
    {label: 'paymentType.DEPOSITO', value: 'DEPOSITO'},
    {label: 'paymentType.OUTROS', value: 'OUTROS'}];
}

export function getPaymentMethodTitles() {
  return {
    'DINHEIRO': 'paymentType.DINHEIRO',
    'PIX': 'paymentType.PIX',
    'CARTAO_CREDITO': 'paymentType.CARTAO_CREDITO',
    'CARTAO_DEBITO': 'paymentType.CARTAO_DEBITO',
    'BOLETO': 'paymentType.BOLETO',
    'TRANSFERENCIA': 'paymentType.TRANSFERENCIA',
    'DEPOSITO': 'paymentType.DEPOSITO',
    'OUTROS': 'paymentType.OUTROS',
  };
}

export function getCurrencyOptions() {
  return [
    {value: 'BRL', label: 'currency.real'},
    {value: 'USD', label: 'currency.dollar'},
    {value: 'EUR', label: 'currency.euro'}];
}

export function getTimezoneOptions() {
  return [
    {value: 'America/Sao_Paulo', label: 'São Paulo'},
    {value: 'America/New_York', label: 'New York'},
    {value: 'America/Los_Angeles', label: 'Los Angeles'}];
}

export function getTypeCategoryOptions() {
  return [
    {label: 'category.GROUP', value: 'GROUP'},
    {label: 'category.CULT', value: 'CULT'},
    {label: 'category.EVENT', value: 'EVENT'},
    {label: 'category.PRAYER', value: 'PRAYER'},
    {label: 'category.FINANCIAL', value: 'FINANCIAL'},
    {label: 'category.SERMON', value: 'SERMON'}];
}
