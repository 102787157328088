import {AfterViewInit, Component} from '@angular/core';
import { NbSidebarService, NbSidebarState } from '@nebular/theme';
import { ThemeConfigService } from '../../services/theme-config.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header subheader class="nb-custom-layout-header">
        <ngx-header class="nb-layout-header nav" (toggleBack)="toggleSidebarCompact()"></ngx-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        responsive
        containerFixed="true"
        (stateChange)="onSidebarStateChange($event)"
        (click)="onVerifyMenu()"
      >
        <div class="sidebar-header">
          <div class="logo clearfix pb-0">
            <a class="logo-text text-left">
              <div class="logo-img">
                <img
                  class="imgLogo"
                  [src]="isMenuExpanded ? imagemLogo : imagemLogoMinimal"
                  [width]="isMenuExpanded ? 150 : 50"
                  [height]="isMenuExpanded ? 50 : 25"
                />
              </div>
            </a>
          </div>
        </div>
        <!-- Close button -->
        <button class="close-button" (click)="toggleSidebar()" *ngIf="isMenuExpanded">
          <nb-icon [icon]="isMenuCompact ? 'toggle-left-outline' : 'toggle-right-outline'" class="text-dark">
          </nb-icon>
        </button>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      <nb-layout-column class="colored-column-basic">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {
  isMenuExpanded: boolean = true;
  isMenuCompact: boolean = false;
  isMenuCollapsed: boolean = false;
  StateMenu: string = '';
  LastStateMenu: string = '';

  imagemLogo: string = 'assets/logo.png';
  imagemLogoMinimal: string = 'assets/logoMinimal.png';

  constructor(
    private sidebarService: NbSidebarService,
    private theme: ThemeConfigService,
  ) {
    const skinData = this.theme.getSkinData();

    if (skinData) {
      this.imagemLogo = this.theme.getImageBase64(skinData.logo) || this.imagemLogo;
      this.imagemLogoMinimal = this.theme.getImageBase64(skinData.logoMinimal) || this.imagemLogoMinimal;
    }

    this.updateSidebarState();
  }

  ngAfterViewInit() {
    this.getStateMenu().subscribe((state) => {
      this.StateMenu = state;
    });

    this.LastStateMenu = this.StateMenu;
  }

  toggleSidebar(): void {
    if (this.StateMenu === 'expanded') {
      this.isMenuCompact = !this.isMenuCompact;
      this.sidebarService.toggle(this.isMenuCompact, 'menu-sidebar');
    } else {
      this.isMenuExpanded = !this.isMenuExpanded;
      this.sidebarService.toggle(this.isMenuExpanded, 'menu-sidebar');
    }
  }

  toggleSidebarCompact(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.sidebarService.toggle(this.isMenuCollapsed, 'menu-sidebar');
  }

  onVerifyMenu(): void {
    if (this.LastStateMenu === 'expanded' && this.StateMenu === 'expanded') { return; }

    if (this.LastStateMenu === 'collapsed' && this.StateMenu === 'expanded') {
      this.onCloseMenu();
    }
  }

  onSidebarStateChange(state: NbSidebarState) {
    this.StateMenu = state;
    this.updateSidebarState();
  }

/*
  onGetSidebarState() {
    this.sidebarService.getSidebarState('menu-sidebar').subscribe((state) => {
      this.StateMenu = state;
    });
  }
*/

  onCloseMenu() {
    this.sidebarService.collapse('menu-sidebar');
    this.LastStateMenu = 'collapsed';
    this.updateSidebarState();
  }

  updateSidebarState() {
    switch (this.StateMenu) {
      case 'collapsed':
        this.isMenuExpanded = false;
        this.isMenuCompact = false;
        this.isMenuCollapsed = true;
        break;
      case 'expanded':
        this.isMenuExpanded = true;
        this.isMenuCompact = false;
        this.isMenuCollapsed = false;
        break;
      case 'compacted':
        this.isMenuExpanded = false;
        this.isMenuCompact = true;
        this.isMenuCollapsed = false;
        break;
      default:
        this.isMenuExpanded = true;
        this.isMenuCompact = false;
        this.isMenuCollapsed = false;
        break;
    }
  }

  getStateMenu(): Observable<NbSidebarState> {
    return this.sidebarService.getSidebarState('menu-sidebar');
  }
}
