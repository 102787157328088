import {Component} from '@angular/core';
import {ThemeConfigService} from '../../@theme/services/theme-config.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-install-page',
  templateUrl: './installPage.component.html',
  styleUrls: ['./installPage.component.scss'],
})
export class InstallPageComponent {
  linkApple = 'https://apps.apple.com/us/app/kirk-digital/id1533940003';
  linkGoogle = 'https://play.google.com/store/apps/details?id=br.com.kirkdigital.kirkdigital';

  constructor(
    private theme: ThemeConfigService,
    private title: Title,
    private translate: TranslateService,
  ) {
    this.translate.get('site.footer.downloadApp').subscribe((res: string) => {
      this.title.setTitle(res);
    });

    const skinData = this.theme.getSkinData();
    this.linkApple = skinData.appApplestore ? skinData.appApplestore : this.linkApple;
    this.linkGoogle = skinData.appPlaystore ? skinData.appPlaystore : this.linkGoogle;
  }
}
