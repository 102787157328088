import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';

import {
  NbAuthService,
  NbTokenStorage,
  NbAuthToken,
} from '@nebular/auth';
import { DadosContaService } from './dadosconta.service';
import {Router} from '@angular/router';
import {NotificationService} from './notificationService.service';
import {AuthService} from '../../auth/services/auth.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshingTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenRefreshedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private originalRequest: HttpRequest<any> = new HttpRequest('GET', '/');
  userInstance: string = '';
  tokenValue: string = '';


  constructor(
    private authService: NbAuthService,
    private tokenService: NbTokenStorage,
    private auth: AuthService,
    private router: Router,
    private notificar: NotificationService,
    private dadosContaService: DadosContaService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtém o token do armazenamento local (por exemplo, LocalStorage ou sessionStorage)
    this.userInstance = this.dadosContaService.getDadosUser();
    this.tokenValue = this.tokenService.get().getValue();

    return this.authService.getToken().pipe(
      switchMap((token: NbAuthToken) => {
        if (token && token.isValid()) {
          // Adiciona o token à solicitação
          request = this.addTokenToRequest(request, this.tokenValue);

          // Adiciona userInstance se estiver presente
          if (this.userInstance) {
            request = this.addUserInstanceToRequest(request, this.userInstance);
          }

          if (this.isRefreshTokenEndpoint(request)) {
            request = this.prepareRequestForRefreshToken(request, this.userInstance);
          }

          // Intercepta a solicitação e manipula erros
          return next.handle(request).pipe(
            catchError((error) => {
              if (this.isUnauthorizedError(error)) {
                // Se o erro for de autenticação, tenta renovar o token e reenviar a solicitação
                return this.refreshTokenAndRetry(request, next);
              } else {
                // Se for outro tipo de erro, lança o erro
                return throwError(error);
              }
            }),
          );
        } else {
          // Se não houver token válido, não tenta renovar o token e continua com a solicitação original
          return next.handle(request);
        }
      }),
    );
  }


  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private addUserInstanceToRequest(request: HttpRequest<any>, userInstance: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'user_instance': userInstance,
      },
    });
  }

  private isRefreshTokenEndpoint(request: HttpRequest<any>): boolean {
    return request.url.includes('refreshtoken') || request.url.includes('signout');
  }

  private prepareRequestForRefreshToken(request: HttpRequest<any>, userInstance: string): HttpRequest<any> {
    if (request.url.includes('refreshtoken')) {
      request = request.clone({
        headers: request.headers.delete('user_instance', userInstance),
      });

      request = request.clone({
        headers: request.headers.delete('Authorization'),
      });
    }
    if (request.url.includes('signout')) {
      request = request.clone({
        body: {},
      });
    }

    return request;
  }

  private isUnauthorizedError(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  private refreshTokenAndRetry(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.refreshingTokenSubject.value) {
      this.refreshingTokenSubject.next(true);

      return this.auth.refreshToken().pipe(
        switchMap((newToken) => {
          if (newToken) {
            this.originalRequest = request.clone(); // Clonar a solicitação original
            if (this.userInstance) {
              this.originalRequest = this.addUserInstanceToRequest(this.originalRequest, this.userInstance);
            }
            this.originalRequest = this.addTokenToRequest(this.originalRequest, newToken);

            // Subscrever à próxima etapa para garantir que o token seja atualizado no armazenamento do Nebular
            return next.handle(this.originalRequest).pipe(
              catchError((error) => {
                if (this.isUnauthorizedError(error)) {
                  this.auth.logoutNebular(); // Logout se a tentativa de atualização do token falhar
                }
                return throwError(error);
              }),
            );
          } else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.refreshingTokenSubject.next(false);
        }),
      );
    } else {
      return this.tokenRefreshedSubject.pipe(
        filter((refreshed) => refreshed),
        take(1),
        switchMap(() => {
          return next.handle(this.originalRequest);
        }),
      );
    }
  }

}
