import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TenantList} from '../models/tenant-list.model';


const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/public/conta`;

@Injectable({
  providedIn: 'root',
})
export class TenantListService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getAll(name: any): Observable<TenantList[]> {
    return this.http.get<TenantList[]>(baseUrl, {params: {name}});
  }
}
