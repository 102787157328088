import {Component, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-take-photo-modal',
  templateUrl: './take-photo-modal.component.html',
})
export class TakePhotoModalComponent implements AfterViewInit {
  @Output() photoTaken = new EventEmitter<string>();
  photo: string | null = null;
  @ViewChild('videoElement') videoElement!: ElementRef;

  constructor(protected ref: NbDialogRef<TakePhotoModalComponent>) {}

  async ngAfterViewInit() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      if (this.videoElement) {
        const video = this.videoElement.nativeElement;
        video.srcObject = stream;
        await video.play();
      }
    } catch (error) {
      console.error('Erro ao iniciar a câmera:', error);
    }
  }

  async takePhoto() {
    try {
      if (this.videoElement) {
        const video = this.videoElement.nativeElement;

        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');

        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL('image/jpeg'); // Converte para base64

        // Emitir a foto capturada para o componente pai
        this.photoTaken.emit(base64Image);

        // Fechar o modal
        this.ref.close(base64Image);
      }
    } catch (error) {
      console.error('Erro ao tirar foto:', error);
    }
  }

  close() {
    this.ref.close();
  }
}
