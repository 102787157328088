import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

const key = '12xHXpceXbz+4WW)(#rZwk7wU3A^.2DZ';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  // Função para criptografar dados
  encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  // Função para descriptografar dados
  decryptData(encryptedData: string): string {
    return CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
  }
}
