import {Component} from '@angular/core';

@Component({
  selector: 'ngx-card-footer',
  template: `
      <nb-card-footer class="text-right">
        <ng-content></ng-content>
      </nb-card-footer>
  `,
})
export class CardFooterComponent {
}
