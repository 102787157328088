import {Component, OnDestroy} from '@angular/core';
import {CarregamentoService} from './carregamento.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ngx-carregamento',
  template: `
    <div *ngIf="isLoading" class="loading-overlay">
      {{ loadingMessage }}
    </div>
  `,
  styles: [`
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
  `],
})
export class CarregamentoComponent implements OnDestroy {
  isLoading: boolean = false;
  loadingMessage: string = '';
  private subscription: Subscription;

  constructor(private loadingService: CarregamentoService) {
    this.subscription = this.loadingService.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.subscription = this.loadingService.loadingMessage$.subscribe(message => {
      this.loadingMessage = message;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
