import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { PermissionUser } from '../model/permissionuser.model';
import { environment } from '../../../../../environments/environment';
import {NotificationService} from '../../../../shared/services/notificationService.service';
import {Router} from '@angular/router';
import {DadosContaService} from '../../../../shared/services/dadosconta.service';


const apiUrl = environment.apiUrl;
const baseUrl = `${apiUrl}/v1/permission`;

@Injectable({
  providedIn: 'root',
})
export class PermissionUserService {
  private permissionDataSubject = new BehaviorSubject<PermissionUser[]>([]);

  constructor(
    private http: HttpClient,
    private notificar: NotificationService,
    private router: Router,
    private dadosconta: DadosContaService,
  ) {}

  permissionUser(): Observable<PermissionUser[]> {
    return this.http.get<PermissionUser[]>(baseUrl);
  }

  carregarPermissoes(): void {
    try {
      this.permissionUser().subscribe((data: PermissionUser[]) => {
        this.dadosconta.saveDadosPermission(data);
        this.permissionDataSubject.next(data);
      });
    } catch (e) {
      this.notificar.showError('message.roleNotPermission');
      this.router.navigate(['/core/account']).then(r => r);
    }
  }

  getPermissionData(): Observable<PermissionUser[]> {
    return this.permissionDataSubject.asObservable();
  }
}
