import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbSidebarService,
} from '@nebular/theme';
import { LayoutService } from '../../utils';
import { Subject } from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-header-account',
  styleUrls: ['./header-account.component.scss'],
  templateUrl: './header-account.component.html',
})
export class HeaderAccountComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  isResponsiveMenuVisible: boolean = true;

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private router: Router) {}

  ngOnInit() {
    this.sidebarService
      .onToggle()
      .subscribe(({ compact, tag }) => {
        if (tag === 'menu-sidebar') {
          this.isResponsiveMenuVisible = compact; // Inverta o estado
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(this.isResponsiveMenuVisible, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    // Verifique se o menu responsivo está visível e atualize o estado
    this.isResponsiveMenuVisible = !this.isResponsiveMenuVisible;

    return false;
  }

  logout() {
    this.router.navigate(['/auth/logout']).then(r => r);
  }
}
