import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserTenantListService} from '../../../core/accounts/service/user-tenant-list.service';
import {UserTenantList} from '../../../core/accounts/model/usertenantlist.model';
import {Observable, Subscription} from 'rxjs';
import {DadosContaService} from '../../services/dadosconta.service';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {MatDialog} from '@angular/material/dialog';
import {DialogAccountComponent} from './dialog/dialog-account/dialog-account.component';

@Component({
  selector: 'ngx-account-selector',
  template: `
      <span *ngIf="accountAtual" (click)="openDialog()" class="account-selector">
            <nb-icon icon="flip-2-outline" *ngIf="!oculatarConta"></nb-icon>
          {{ accountAtual ? accountAtual : 'Selecione uma conta' }}
    </span>
  `,
  styleUrls: ['./account-selector.component.scss'],
})
export class AccountSelectorComponent implements OnInit, OnDestroy {
  @Input() isMultitenant: boolean = false;
  @Input() oculatarConta: boolean = false;
  accountAtual: string = '';
  userTenantList$!: Observable<UserTenantList[]>;
  openDialogBool: boolean = false;

  selectedAccount: any;
  private subscription: Subscription = new Subscription();

  usuario: {
    userId: string;
    roles: string;
  };

  constructor(
    private userTenantListService: UserTenantListService,
    private dadosContaService: DadosContaService,
    private authService: NbAuthService,
    public dialog: MatDialog,
  ) {
    this.getUser();
  }

  openDialog(): void {
    if (this.openDialogBool) {
      return;
    }

    const dialogRef = this.dialog.open(DialogAccountComponent, {
      data: {
        userTenantList$: this.userTenantList$,
        selectedAccount: this.selectedAccount,
        usuario: this.usuario,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.accountAtual = data.name_conta;
      }
    });
  }

  ngOnInit() {
    // Certifique-se de inicializar o serviço antes de acessar os dados
    this.subscription.add(this.userTenantListService.getAll().subscribe(() => {
      // Agora podemos obter os dados
      this.userTenantList$ = this.userTenantListService.getStoredData();
      this.accountAtual = this.dadosContaService.getDadosAccount();
      this.oculatarConta = !this.isMultitenant;
      // quando tiver apenas um ou nenhum item, desabilita o dropdown
      this.subscription.add(this.userTenantList$.subscribe((userTenantList) => {
        if (userTenantList.length <= 1 || !userTenantList) {
          this.selectedAccount = userTenantList[0];
          this.openDialogBool = true;
        }
      }));
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  getUser() {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.usuario = token.getPayload();
      }
    });
  }
}
