import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, BehaviorSubject, from, interval, EMPTY} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {DadosContaService} from '../../shared/services/dadosconta.service';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {CarregamentoService} from '../../shared/layout/loading/carregamento.service';
import {NotificationService} from '../../shared/services/notificationService.service';
const helper = new JwtHelperService();
const urlApi = environment.apiUrl;

@Injectable({providedIn: 'root'})

export class AuthService {
  public user: Observable<any> = new BehaviorSubject<any>(null);
  private userData = new BehaviorSubject(null);
  private tokenRefreshTimer$: Observable<number>;

  private storage: Storage;
  private readonly TOKEN_KEY = 'auth_app_token';
  private readonly REFRESH_TOKEN_KEY = 'auth-refresh-token';
  private readonly USER_DATA_KEY = 'auth-user-data';
  private readonly TOKEN_EXPIRATION_KEY = 'tokenExpiration';
  private readonly TOKEN_REFRESH_INTERVAL = 60 * 60 * 1000; // 60 minutos em milissegundos
  private readonly TOKEN_EXPIRATION_TIME = 1 * 24 * 60 * 60 * 1000; // 1 dias em milissegundos

  constructor(
    private http: HttpClient,
    private router: Router,
    private dadosContaService: DadosContaService,
    private loadingService: CarregamentoService,
    private notificar: NotificationService,
  ) {
    this.storage = window.localStorage;
    this.loadStoredToken();
    this.tokenRefreshTimer$ = this.setupTokenRefreshTimer();
  }

  loadStoredToken() {
    const token = this.storage.getItem(this.TOKEN_KEY);
    if (token) {
      const decoded = helper.decodeToken(token) as any;
      this.storeTokenExpiration(decoded.exp * 1000);
      return true;
    } else {
      return null;
    }
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    this.dadosContaService.clearDados();
    this.storage.set(this.TOKEN_KEY, null);
    this.storage.set(this.REFRESH_TOKEN_KEY, null);
    this.storage.set(this.USER_DATA_KEY, null);

    this.loadingService.show('loading.user');
    return this.http.post(`${urlApi}/auth/signin`, credentials).pipe(
      take(1),
      map((res: any) => {
        if (res) {
          this.storage.set(this.TOKEN_KEY, res.accessToken);
          this.storage.set(this.REFRESH_TOKEN_KEY, res.refreshToken);
          this.storage.set(this.USER_DATA_KEY, ({ id: res.id, roles: res.roles }));
          this.userData.next(res);
          this.loadingService.hide();
          return true;
        } else {
          this.loadingService.hide();
          return null;
        }
      }),
      catchError(() => {
        this.notificar.showError('message.invalidUser');
        this.loadingService.hide();
        return EMPTY;
      }),
    );
  }

  register(data: any, tenant: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/signup`, data, {headers: {'select_account': tenant}});
  }

  registerInvite(data: any, tenant: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/invite/accept`, data, {headers: {'invite_token': tenant}});
  }

  registerIntegrador(data: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/signup/institution`, data);
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/forgot-password?email=${data}`, null);
  }

  getUsuario(): Observable<any> {
    return this.http.get(`${urlApi}/auth/user`);
  }

  alterPassword(data: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/change-password`, data);
  }

  logoutNebular(): Observable<any> {
    return this.http.post(`${urlApi}/auth/signout`, null);
  }

  public logout() {
    this.loadingService.show('loading.logout');

    this.http.post(`${urlApi}/auth/signout`, {}).subscribe(
      {next: () => {
          this.userData.next(null);
          this.dadosContaService.clearDados();
          this.storage.set(this.TOKEN_KEY, null);
          this.storage.set(this.REFRESH_TOKEN_KEY, null);
          this.storage.set(this.USER_DATA_KEY, null);
          this.router.navigateByUrl('/').then();
        },
        error: () => {
          this.notificar.showErroInesperado();
        },
        complete: () => {
          this.loadingService.hide();
        },
      },
    );
  }

  deslogar() {
    this.storage.removeItem(this.TOKEN_KEY);
    this.storage.removeItem(this.REFRESH_TOKEN_KEY);
    this.storage.removeItem(this.USER_DATA_KEY);
    this.userData.next(null);

    // remover do nebular
    this.storage.removeItem('auth_app_token');

    this.dadosContaService.clearDados();

    this.logoutNebular().subscribe(() => {
      this.user = new BehaviorSubject(null);
    });

    this.router.navigate(['/auth/login']).then(r => r);

  }

  async refreshTokenIfNeeded(): Promise<Observable<any>> {
    const expirationTimeStr = this.storage.getItem(this.TOKEN_EXPIRATION_KEY);
    if (!expirationTimeStr) {
      return EMPTY;
    }
    const expirationTime = parseInt(expirationTimeStr, 10); // Converter para número
    const currentTime = new Date().getTime();
    if (expirationTime - currentTime < this.TOKEN_EXPIRATION_TIME) {
      return this.refreshToken();
    }
    return EMPTY; // Retorna um Observable vazio
  }

  refreshToken(): Observable<any> {
    return from(this.storage.getItem(this.REFRESH_TOKEN_KEY)).pipe(
      switchMap((refreshToken) => {
        if (!refreshToken) {
          return EMPTY;
        }
        return this.http.post(urlApi + '/auth/refreshtoken', { refreshToken: refreshToken }).pipe(
          map((response: any) => {
            const newToken = response.accessToken;
            const decoded = helper.decodeToken(newToken);
            this.storeToken(newToken);
            this.storeTokenExpiration(decoded.exp * 1000); // Multiplica por 1000 para converter para milissegundos
            return newToken;
          }),
          catchError(() => {
            return EMPTY;
          }),
          take(1),
        );
      }),
    );
  }

  getToken(): Observable<any> {
    return from(this.storage.getItem(this.TOKEN_KEY) || '');
  }

  async getUserInfo() {
    const token = this.storage.getItem(this.TOKEN_KEY);
    const decoded = await helper.decodeToken(token);
    return {
      email: decoded.sub,
      userId: decoded.userId,
      roles: decoded.roles, // Substitua 'permissions' pelo nome real do campo que armazena as permissões no token
    };
  }

  public getUserData(): Observable<any> {
    return this.userData.asObservable();
  }

  alterEmail(data: any): Observable<any> {
    return this.http.post(`${urlApi}/auth/change-email`, data);
  }

  storeToken(token: string): void {
    this.storage.setItem(this.TOKEN_KEY, token);
  }

  storeTokenExpiration(expirationTime: number): void {
    this.storage.setItem(this.TOKEN_EXPIRATION_KEY, expirationTime.toString());
  }

  setupTokenRefreshTimer(): Observable<any> {
    return interval(this.TOKEN_REFRESH_INTERVAL).pipe(
      switchMap(() => this.refreshTokenIfNeeded()),
    );
  }

  consultarTokenInvite(token: string): Observable<any> {
    return this.http.get(`${urlApi}/auth/invite/consult?invite_token=${token}`);
  }
}
