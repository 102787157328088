<h2 mat-dialog-title>🏬 {{ 'actions.selectAccount' | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{ 'message.messageAccountAlert3' | translate }}:</p>
  <input placeholder="{{'actions.search' | translate}}" class="" nbInput (input)="search($event.target.value)" fullWidth>
  <mat-action-list>
    <button mat-list-item *ngFor="let account of data.userTenantList$ | async" (click)="accountSelected(account)">
      <nb-icon icon="layers-outline"></nb-icon>
      {{ account.name_conta }}</button>
  </mat-action-list>
</mat-dialog-content>
