import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TakePhotoModalComponent} from '../take-photo-modal/take-photo-modal.component';
import {NbDialogService} from '@nebular/theme';
import {ZoomPhotoModalComponent} from '../zoom-photo-modal/zoom-photo-modal.component';

@Component({
  selector: 'ngx-imagem-profile',
  template: `
    <nb-card-body>
      <div class="row mt-2">
        <div class="col-md-1">
          <nb-user
            size="giant"
            [name]="namePerson"
            [picture]="base64ImageForDisplay ? base64ImageForDisplay : null"
            onlyPicture
            (click)="base64ImageForDisplay ? openImageModal() : null"
          ></nb-user>
        </div>
        <div class="col-md-4 mt-auto ml-5" *ngxPermissionsOnly="['MY_DATA_ATUALIZAR', 'PERSON_ATUALIZAR']">
            <button nbButton status="success" (click)="openTakePhotoDialog($event)" nbTooltip="{{ 'actions.takePicture' | translate}}">
              <nb-icon icon="camera-outline"></nb-icon>
              {{ 'actions.takePicture' | translate}}</button>
            <label class="custom-file-upload">
              <input type="file" (change)="onFileSelected($event)" accept="image/*" >
              <nb-icon icon="upload-outline" nbTooltip="{{ 'actions.selectImage' | translate}}"></nb-icon>
            </label>
        </div>
      </div>
    </nb-card-body>
  `,
  styleUrls: ['./imagem-profile.component.scss'],
})
export class ImagemProfileComponent {
  @Input() namePerson: string | null = null;
  @Input() set base64Image(value: string | null) {
    this.base64ImageForDisplay = value ? this.tratarImagem(value) : this.tratarImagem(this.base64ImageForDisplay);
  }
  @Output() base64ImageChange = new EventEmitter<string | null>();
  @Output() imagemBytesChange = new EventEmitter<Uint8Array | null>();

  private base64ImageWithPrefix: string | null = null;
  public base64ImageForDisplay: string | null = null;

  constructor(private dialogService: NbDialogService) {}

  onFileSelected(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      // Remover o prefixo "data:image/jpeg;base64,"
      const base64String = e.target.result.split(',')[1];

      // Manter o prefixo para exibição
      this.base64ImageWithPrefix = e.target.result;

      this.base64ImageChange.emit(base64String); // Emitir a string base64 sem o prefixo para o componente pai

      // Converter a string base64 para um array de bytes
      const binaryString = atob(base64String);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      this.imagemBytesChange.emit(byteArray); // Emitir o array de bytes para o componente pai
    };

    reader.readAsDataURL(file);
  }

  openTakePhotoDialog(event: any) {
    event.preventDefault(); // Evita o comportamento de submit
    this.dialogService.open(TakePhotoModalComponent).onClose.subscribe((photo: string) => {
      if (photo) {
        const base64String = photo.split(',')[1];
        this.base64Image = base64String;
        this.base64ImageChange.emit(base64String); // Emitir a string base64 sem o prefixo para o componente pai
      }
    });
  }
  tratarImagem(imagem: string): string {   // Função para tratar a imagem
    if (!imagem) {
      return '';
    }
    return `data:image/jpeg;base64,${imagem}`;
  }

  openImageModal() {
    this.dialogService.open(ZoomPhotoModalComponent, {
      context: {
        photo: this.base64ImageForDisplay,
      },
    });
  }
}
