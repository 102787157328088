import { Injectable } from '@angular/core';
import { PublicService } from '../../shared/services/publicService.service';
import { NbThemeService } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import {EncryptService} from '../../shared/services/encrypt.service';
const SKIN_KEY = 'skinData';

@Injectable()
export class ThemeConfigService {
  private domain: string;
  private skinData: any;
  private themeName: string = 'default';

  private themeSubject = new BehaviorSubject<string>(this.themeName);

  constructor(
    private publicService: PublicService,
    private themeService: NbThemeService,
    private encryptService: EncryptService,
  ) {}

  public getSkinData(): any {
    const skinData = window.sessionStorage.getItem(SKIN_KEY);
    if (skinData) {
      const dadosDescriptografados = this.encryptService.decryptData(skinData);
      return JSON.parse(dadosDescriptografados);
    }
    return '';
  }

  public saveSkinData(skinData: any): void {
    const encryptedSkinData = this.encryptService.encryptData(JSON.stringify(skinData));
    window.sessionStorage.setItem(SKIN_KEY, encryptedSkinData);
  }

  public getImageBase64(image: string): string {
    return 'data:image/png;base64,' + image;
  }

  async initialize(): Promise<void> {
    // Tenta obter os dados da skin do sessionStorage
    const storedSkinData = this.getSkinData();

    if (storedSkinData) {
      // Se os dados da skin já estiverem no sessionStorage, use-os diretamente
      this.skinData = storedSkinData;
      this.themeName = this.domain === 'default' ? 'default' : 'azul';
      this.themeService.changeTheme(this.getSkinData().cor);
      this.notifyThemeChange();
    } else {
      // Se os dados da skin não estiverem no sessionStorage, consulta a API
      this.domain = this.getSubdomain();
      try {
        this.skinData = await this.publicService.getSkinByDomain(this.domain).toPromise();
        this.themeName = this.domain === 'default' ? 'default' : 'azul';

        this.saveSkinData(this.skinData);
        this.themeService.changeTheme(this.getSkinData().cor);
        if (this.skinData.favicon) {
          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          favicon.href = 'data:image/png;base64,' + this.skinData.favicon;
        }
        this.notifyThemeChange();
      } catch (error) {
        console.error('Erro ao buscar dados do skin:', error);
      }
    }
  }


  private notifyThemeChange() {
    this.themeSubject.next(this.themeName);
  }

  public getSubdomain(): string {
    const domainParts = window.location.hostname.split('.');
    const secondLevelDomain = domainParts[domainParts.length - 2];

    if (domainParts.length >= 3) {
      return domainParts[domainParts.length - 3];
    } else {
      return secondLevelDomain;
    }
  }
}
